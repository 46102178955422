import { ImgWrapper } from "pages/platform/style";
import React from "react";
import { ElementWrapper, Heading, Img, ListWrapper, Title } from "style/layout";
import training from "assets/png/training.png";

function Training(): JSX.Element {
  return (
    <ElementWrapper>
      <Heading>전문 인력 양성</Heading>
      <ImgWrapper>
        <Img src={training} alt="전문인력양성" />
      </ImgWrapper>
      <ListWrapper>
        <Title>교육 프로그램</Title>
        <li>기존 장비 교육</li>
        <li>AI기반 신규장비 교육</li>
        <li>빅데이터 AI 활용기술 교육</li>
        <li>의료기기 인허가 관련 교육</li>
      </ListWrapper>
      <br />
      <Title>교육 신청 및 문의</Title>
      <address>
        <b>AI헬스케어의료기기상용화지원센터</b>
        <br />
        운영사무실 062-220-4659
      </address>
    </ElementWrapper>
  );
}

export default Training;
