import { createFnQ } from "lib/req";
import { useState } from "react";
import Button from "style/button";
import Input, { Textarea } from "style/input";
import { ElementWrapper, Heading } from "style/layout";
import styled from "styled-components";

const CreateWrapper = styled.section`
  margin-top: 30px;
`;

const QnaCreate = () => {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");

  async function create() {
    createFnQ({ q: title, a: content });
  }

  return (
    <ElementWrapper>
      <Heading>QNA 글쓰기</Heading>
      <CreateWrapper>
        <Input
          onChange={e => setTitle(e.currentTarget.value)}
          placeholder="Q"
          type="text"
        />
        <Textarea
          onChange={e => setContent(e.currentTarget.value)}
          placeholder="A"
        />
        <Button onClick={() => create()}>저장</Button>
      </CreateWrapper>
    </ElementWrapper>
  );
};

export default QnaCreate;
