import Table, { TableBtn } from "components/managementTable";
import { INews, IAnnouncement, IEquipment, IFnQ } from "lib/dto";
import {
  getNewsPage,
  getAnnouncementPage,
  deleteNews,
  deleteAnnouncement,
  getEquipmentPage,
  getFnQPage,
  deleteEquipment,
  deleteFnQ,
} from "lib/req";
import LogoEditModal from "pages/logoCreate";
import QNAEditModal from "pages/qna/edit";
import React, { useEffect, useState } from "react";
import Button from "style/button";
import { ElementWrapper, Heading, Title } from "style/layout";
import * as S from "./style";

function Admin(): JSX.Element {
  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      const exp = JSON.parse(window.atob(token.split(".")[1]))["exp"] || 0;
      if (Date.now() <= exp * 1000) {
        return;
      }
    }
    global.location.replace("/");
  }, []);

  // 뉴스
  const [news, setNews] = useState<{
    news: INews[];
    totalPage: number;
  }>();
  const [newsCurrentPage, setNewsCurrentPage] = useState(1);

  useEffect(() => {
    getNewsPage(newsCurrentPage).then(res => res && setNews(res.data));
  }, [newsCurrentPage]);

  // 공지사항
  const [posts, setPosts] = useState<{
    announcement: IAnnouncement[];
    totalPage: number;
  }>();
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    getAnnouncementPage(currentPage).then(res => res && setPosts(res.data));
  }, [currentPage]);

  // 장비
  const [equipment, setEquipment] = useState<{
    equipment: IEquipment[];
    totalPage: number;
  }>();
  const [equipmentCurrentPage, setEquipmentCurrentPage] = useState(1);

  useEffect(() => {
    getEquipmentPage(equipmentCurrentPage).then(
      res => res && setEquipment(res.data)
    );
  }, [equipmentCurrentPage]);

  // Q&A
  const [qna, setQna] = useState<{
    fnq: IFnQ[];
    totalPage: number;
  }>();
  const [qnaCurrentPage, setQnaCurrentPage] = useState(1);

  useEffect(() => {
    getFnQPage(qnaCurrentPage).then(res => res && setQna(res.data));
  }, [qnaCurrentPage]);

  return (
    <ElementWrapper>
      <Heading>관리자 페이지</Heading>
      <div>
        <Title>공지</Title>
        <Table
          path={"notice"}
          posts={
            posts?.announcement.length === 0 ? undefined : posts?.announcement
          }
          onDelete={(idx: number) => deleteAnnouncement(idx)}
        />
        <TableBtn
          preBtnOnClick={() =>
            currentPage !== 1 && setCurrentPage(currentPage - 1)
          }
          nextBtnOnClick={() =>
            posts &&
            currentPage < posts.totalPage &&
            setCurrentPage(currentPage + 1)
          }
          currentPage={currentPage}
          totalPage={posts?.totalPage || 1}
        />
      </div>
      <div>
        <Title>보도자료/뉴스</Title>
        <Table
          path={"news"}
          posts={news?.news.length === 0 ? undefined : news?.news}
          onDelete={(idx: number) => deleteNews(idx)}
        />
        <TableBtn
          preBtnOnClick={() =>
            newsCurrentPage !== 1 && setNewsCurrentPage(newsCurrentPage - 1)
          }
          nextBtnOnClick={() =>
            news &&
            currentPage < news.totalPage &&
            setNewsCurrentPage(newsCurrentPage + 1)
          }
          currentPage={newsCurrentPage}
          totalPage={news?.totalPage || 1}
        />
      </div>
      <div>
        <Title>장비</Title>
        <Table
          isCount={false}
          path={"use"}
          posts={
            equipment?.equipment?.length === 0
              ? undefined
              : equipment?.equipment
          }
          onDelete={(idx: number) => deleteEquipment(idx)}
        />
        <TableBtn
          preBtnOnClick={() =>
            equipmentCurrentPage !== 1 &&
            setEquipmentCurrentPage(equipmentCurrentPage - 1)
          }
          nextBtnOnClick={() =>
            equipment &&
            currentPage < equipment.totalPage &&
            setEquipmentCurrentPage(equipmentCurrentPage + 1)
          }
          currentPage={equipmentCurrentPage}
          totalPage={equipment?.totalPage || 1}
        />
      </div>
      <div>
        <Title>Q&A</Title>
        <S.Table>
          {qna?.fnq ? (
            <>
              <thead>
                <tr>
                  <th>질문</th>
                  <th>답변</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {qna?.fnq.map(data => (
                  <tr key={data.idx}>
                    <td>{data.q}</td>
                    <td>{data.a}</td>
                    <td>
                      <QNAEditModal idx={data.idx}>수정</QNAEditModal>
                    </td>
                    <td>
                      <button onClick={() => deleteFnQ(data.idx)}>삭제</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </>
          ) : (
            <>
              <thead>
                <tr>
                  <th>No</th>
                  <th>질문</th>
                  <th>답변</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>데이터가 존재하지 않습니다.</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </>
          )}
        </S.Table>
        <Button onClick={() => global.location.assign(`qna-create`)}>
          글쓰기
        </Button>
        <TableBtn
          preBtnOnClick={() =>
            qnaCurrentPage !== 1 && setQnaCurrentPage(qnaCurrentPage - 1)
          }
          nextBtnOnClick={() =>
            qna &&
            currentPage < qna.totalPage &&
            setQnaCurrentPage(qnaCurrentPage + 1)
          }
          currentPage={qnaCurrentPage}
          totalPage={qna?.totalPage || 1}
        />
      </div>
      <div>
        <Title>참여 기업</Title>
        <LogoEditModal>참여 기업 수정하기</LogoEditModal>
      </div>
    </ElementWrapper>
  );
}

export default Admin;
