import React from "react";
import CI1 from "assets/png/CI1.png";
import CI2 from "assets/png/CI2.png";
import { ElementWrapper, Heading } from "style/layout";
import * as S from "./style";

function CI(): JSX.Element {
  return (
    <ElementWrapper>
      <Heading>CI</Heading>
      <S.AlignCenter>
        <img src={CI1} alt="logo" />
        <S.Download href="https://drive.google.com/file/d/14YQkfYKRDqyb0vOZshV_BG0F2xjdczed/view?usp=sharing">
          CI 다운로드
        </S.Download>
        <img src={CI2} alt="name" />
        <S.Download href="https://drive.google.com/file/d/1oKfZt_vJQK0oElL5McKpBjH5qVQdMhw7/view?usp=sharing">
          CI 다운로드
        </S.Download>
      </S.AlignCenter>
    </ElementWrapper>
  );
}

export default CI;
