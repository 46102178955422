import React from "react";
import Logo from "assets/svg/logo";
import HOSPITAL_LOGO from "assets/png/hospital_logo.png";
import "./style.css";
import styled from "styled-components";

export const Img = styled.img`
  height: 35px;
  width: 180px;
`;

function Footer(): JSX.Element {
  return (
    <footer>
      <div>
        <Img src={HOSPITAL_LOGO} />
        <Logo width={180} height={35} />
        <div>
          <address>
            <b>
              61469 광주광역시 동구 백서로 160 4층
              (AI헬스케어의료기기상용화지원센터)
            </b>
            <br />
            전화: 062-220-4659
            <br />
            FAX: 062-220-4664
          </address>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
