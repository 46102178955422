import styled from "styled-components";

const Button = styled.button`
  padding: 0.625rem;
  background-color: #008cd6;
  color: #fff;
  border: none;
  cursor: pointer;
  width: 100%;
  &:hover {
    background-color: #006fa9;
    color: #fff;
  }
`;

export default Button;
