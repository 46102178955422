import styled from "styled-components";

export const ListWrapper = styled.ul`
  list-style: inside;
  line-height: 1.875rem;
`;

export const Img = styled.img`
  width: 100%;
`;
