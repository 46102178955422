import React from "react";

interface LogoProps {
  width?: number;
  height?: number;
}

function Logo(p: LogoProps): JSX.Element {
  return (
    <svg
      width={p.width ?? 316}
      height={p.height ?? 65}
      viewBox="0 0 316 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M115.5 7.20007V22.3001H111.5V18.2001H108.9C107.9 20.3001 105.6 21.7001 102.9 21.7001C99.2 21.7001 96.5 19.2001 96.5 15.8001C96.5 14.7001 96.8 13.6001 97.5 12.7001H95.5V9.20007H100.7V6.20007H105.1V9.20007H110.1V12.7001H108.4C108.8 13.3001 109.1 13.9001 109.2 14.6001H111.5V7.20007H115.5ZM102.9 18.0001C104.3 18.0001 105.4 17.1001 105.4 15.5001C105.4 14.1001 104.4 12.8001 102.9 12.8001C101.5 12.8001 100.4 14.0001 100.4 15.5001C100.5 17.1001 101.5 18.0001 102.9 18.0001ZM121.5 36.7001H100.9V28.5001H116.7V27.1001H100.9V23.7001H121.1V31.6001H105.3V33.2001H121.5V36.7001ZM121.1 6.80008V22.3001H117V6.80008H121.1Z"
        fill="#002870"
      />
      <path
        d="M123.8 29.4001H150.7V33.0001H123.8V29.4001ZM147 24.2001C142 22.1001 139 20.1001 137.3 18.1001C135.5 20.2001 132.6 22.2001 127.5 24.4001L125.6 20.9001C133.3 17.5001 135 14.9001 135 11.7001V8.00012H139.5V11.7001C139.5 14.9001 141.3 17.4001 149 20.8001L147 24.2001Z"
        fill="#002870"
      />
      <path
        d="M170.5 7.20004V35.1001H166.4V21.1001H162C160.6 24.5001 158.2 27.5 154.2 30.5L151.5 27.5C154.7 25.2 156.6 22.9 157.8 20.4H152.1V16.8H159C159.3 15.4 159.4 13.9 159.4 12.2H152.1V8.60005H163.6V11.4C163.6 13.6 163.4 15.6 163 17.5H166.3V7.20004H170.5ZM176.8 6.80005V36H172.6V6.80005H176.8Z"
        fill="#002870"
      />
      <path
        d="M204 6.80005V36H199.6V19.7H196.2C195.4 23.5 192.1 26.2 188 26.2C183.3 26.2 179.6 22.5 179.6 17.9C179.6 13.3 183.3 9.60005 188 9.60005C192 9.60005 195.4 12.4001 196.2 16.1001H199.6V6.80005H204ZM188.1 13.3C185.8 13.3 184 15.4 184 17.9C184 20.6 185.7 22.6001 188.1 22.6001C190.4 22.6001 192.2 20.6 192.2 18C192.2 15.4 190.4 13.3 188.1 13.3Z"
        fill="#002870"
      />
      <path
        d="M226.6 26.9V30.6001H207.5V26.9H226.6ZM208.8 15.4C208.8 10.9 212.6 7.70004 217 7.70004C221.3 7.70004 225.2 10.9 225.2 15.4C225.2 20 221.4 23.1001 217 23.1001C212.6 23.1001 208.8 20 208.8 15.4ZM220.8 15.4C220.8 13 219.1 11.4 217 11.4C214.9 11.4 213.2 13 213.2 15.4C213.2 17.8 214.9 19.4 217 19.4C219.1 19.5 220.8 17.9 220.8 15.4ZM232.5 6.80005V36H228.1V6.80005H232.5Z"
        fill="#002870"
      />
      <path
        d="M235.2 29.4001H242V25.1001H238.7V14.8001H254.1V11.8001H238.7V8.20007H258.5V18.4001H243.1V21.5001H259V25.1001H255.3V29.4001H262.2V33.0001H235.3V29.4001H235.2ZM251 29.4001V25.1001H246.4V29.4001H251Z"
        fill="#002870"
      />
      <path
        d="M264.1 27.2C272 22.7 274.3 18.5 274.3 12.2H264.3V8.60005H278.6V11.4C278.6 18.8 276.3 25.0001 266.5 30.6001L264.1 27.2ZM287.6 6.80005V36H283.2V6.80005H287.6Z"
        fill="#002870"
      />
      <path
        d="M292 27.2C299.9 22.7 302.2 18.5 302.2 12.2H292.2V8.60005H306.5V11.4C306.5 18.8 304.2 25.0001 294.4 30.6001L292 27.2ZM315.4 6.80005V36H311V6.80005H315.4Z"
        fill="#002870"
      />
      <path
        d="M99.6001 43.2H102.7V45.8C102.7 47.8 103.6 48.7 107.6 51.6L105.8 53.7C103.6 52.2 102.2 50.9 101.2 49.8C100.3 51 98.8001 52.4 96.5001 54L94.7001 51.8C98.6001 48.9 99.6001 47.8 99.6001 45.7V43.2ZM105.7 64.3C102.4 64.3 100 62 100 59.3C100 56.6 102.4 54.3 105.7 54.3C109 54.3 111.4 56.7 111.4 59.3C111.4 62 109 64.3 105.7 64.3ZM105.7 56.8C104.1 56.8 103.1 58.1 103.1 59.3C103.1 60.5 104 61.8 105.7 61.8C107.3 61.8 108.3 60.5 108.3 59.3C108.3 58.1 107.4 56.8 105.7 56.8ZM111.8 42.9V46.5H115.1V49H111.8V54H108.7V42.9H111.8Z"
        fill="#002870"
      />
      <path
        d="M115.4 52.4001H119.8V49.3001C119.2 48.6001 118.9 47.8001 118.9 46.8001C118.9 44.2001 121.4 42.2001 124.8 42.2001C128.2 42.2001 130.7 44.2001 130.7 46.8001C130.7 47.7001 130.4 48.5001 130 49.1001V52.4001H134.3V54.8001H115.4V52.4001ZM124.9 64.3001C121.5 64.3001 119.2 62.4001 119.2 59.9001C119.2 57.4001 121.5 55.5001 124.9 55.5001C128.3 55.5001 130.6 57.5001 130.6 59.9001C130.6 62.4001 128.3 64.3001 124.9 64.3001ZM124.8 44.6001C123.1 44.6001 122 45.5001 122 46.7001C122 48.0001 123.1 48.8001 124.8 48.8001C126.5 48.8001 127.6 47.9001 127.6 46.7001C127.6 45.5001 126.5 44.6001 124.8 44.6001ZM124.9 57.9001C123.2 57.9001 122.3 58.9001 122.3 59.9001C122.3 60.9001 123.2 61.9001 124.9 61.9001C126.6 61.9001 127.5 60.9001 127.5 59.9001C127.5 58.9001 126.6 57.9001 124.9 57.9001ZM122.8 51.1001V52.4001H126.9V51.1001C126.2 51.3001 125.5 51.4001 124.7 51.4001C124.1 51.3001 123.5 51.3001 122.8 51.1001Z"
        fill="#002870"
      />
      <path
        d="M147.8 57.3V59.9H134.9V57.3H140V55.2C137.8 54.7 136.1 53 136.1 50.7C136.1 49.6 136.5 48.7 137.1 47.9H135.2V45.3H139.8V42.9H142.9V45.3H147.5V47.9H145.7C146.3 48.7 146.7 49.6 146.7 50.7C146.7 53 145 54.6 142.9 55.2V57.3H147.8ZM141.4 52.9C142.6 52.9 143.7 51.9 143.7 50.4C143.7 48.9 142.6 47.9 141.4 47.9C140.1 47.9 139.1 48.9 139.1 50.4C139.1 51.8 140.1 52.9 141.4 52.9ZM152 42.9V50.7H154.8V53.3H152V63.3H148.9V42.9H152Z"
        fill="#002870"
      />
      <path
        d="M155.8 44.4H167V47H163V48.8C163 51.7 163.7 52.9 168.1 56.5L166.3 58.6C164 56.9 162.5 55.5 161.5 54.2C160.6 55.6 159.1 57 156.7 59L154.7 56.9C158.9 53.3 159.8 52.3 159.8 48.7V47H155.7V44.4H155.8ZM172.4 42.9V63.3H169.3V42.9H172.4Z"
        fill="#002870"
      />
      <path
        d="M183.2 54.2001V56.9001H180.2V54.2001H175.1V51.7001H188.3V54.2001H183.2ZM187 46.9001C187 49.2001 185 51.2001 181.8 51.2001C178.6 51.2001 176.6 49.2001 176.6 47.0001C176.6 44.7001 178.5 42.6001 181.8 42.6001C185 42.6001 187 44.6001 187 46.9001ZM181.8 60.8001H193V63.3001H178.7V57.5001H181.8V60.8001ZM184 46.9001C184 45.9001 183.1 45.1001 181.8 45.1001C180.4 45.1001 179.6 46.0001 179.6 46.9001C179.6 47.9001 180.5 48.7001 181.8 48.7001C183.1 48.7001 184 47.9001 184 46.9001ZM189.6 57.5001H185.4V55.1001H189.6V42.9001H192.7V58.7001H189.6V57.5001Z"
        fill="#002870"
      />
      <path
        d="M197.9 43.6H200.9V46C200.9 48.6 201.2 49.8 204.8 52.2L203.2 54.4C201.5 53.3 200.3 52.3 199.5 51.2C198.7 52.4 197.5 53.6 195.6 54.9L194 52.8C197.5 50.2 197.9 48.8 197.9 46V43.6ZM201.4 56.7V60.8H212.8V63.3H198.3V56.7H201.4ZM208.2 43.2V57.2H205.3V49.1H202.6V46.5H205.3V43.1H208.2V43.2ZM212.5 42.9V57.6H209.5V42.9H212.5Z"
        fill="#002870"
      />
      <path
        d="M224.1 49.9V52.4H218.6V55.8H226.2V58.4H215.5V44H225.1V46.6H218.6V49.9H224.1ZM231.9 42.9V63.3H228.8V52.3H225.5V49.8H228.8V43H231.9V42.9Z"
        fill="#002870"
      />
      <path
        d="M67.0001 63.7001L65.5001 60.8001L58.8 47.5001C58.2001 46.4001 57.1001 45.7001 55.9001 45.7001H22.9001C22.1001 45.7001 21.4001 46.2001 21.0001 46.9001L12.5001 63.8001L12.2001 64.5001H21.2001L21.3 64.2001L26.5001 53.7001H41.3L46.5001 64.2001L46.6001 64.5001H55.4001L55.1001 63.8001L52.6001 58.8001C52.3001 58.2001 51.7001 57.7001 51.1001 57.6001C49.7001 57.3001 48.5001 56.3001 48.0001 55.0001C47.3001 53.2001 48.0001 51.1001 49.6001 50.1001C50.3001 49.6001 51.1001 49.4001 51.9001 49.4001C52.3001 49.4001 52.6001 49.4001 53.0001 49.5001C54.2001 49.8001 55.1001 50.6001 55.7001 51.7001C56.3001 52.9001 56.3001 54.2001 55.7001 55.4001C55.4001 56.0001 55.4001 56.7001 55.7001 57.3001L59.2001 64.3001L59.3 64.6001H59.6001H63.3H66.6001H67.4001L67.0001 63.7001Z"
        fill="url(#paint0_linear_23_296)"
      />
      <path
        d="M24.2001 31.1C23.6001 29.9 23.6001 28.6 24.2001 27.4C24.7001 26.3 25.7001 25.6 26.9001 25.2C27.3001 25.1 27.6001 25.1 28.0001 25.1C28.8001 25.1 29.6 25.3 30.3 25.8C31.9 26.9 32.6001 28.9 31.9001 30.7C31.4001 32.1 30.2 33.1 28.8 33.3C28.1 33.4 27.6 33.9 27.3 34.5L25.0001 39.1C24.7001 39.8 24.7001 40.5 25.1001 41.2C25.5001 41.8 26.2001 42.2 26.9001 42.2H52.4001C53.3001 42.2 54.0001 41.8 54.5001 41C54.9001 40.3 55.0001 39.4 54.6001 38.6L36.2001 1.80003C35.8001 0.900034 34.9001 0.400024 34.0001 0.400024C33.1001 0.400024 32.2 0.900034 31.8 1.80003L29.8 5.80003L29.7001 6.00003L29.8 6.20003L41.0001 29C41.3001 29.6 41.9001 30 42.5001 30.2C44.0001 30.5 45.2001 31.5 45.7001 33C46.3001 34.7 45.7001 36.6 44.2001 37.7C43.5001 38.2 42.6001 38.5 41.7001 38.5C41.4001 38.5 41.0001 38.5 40.7001 38.4C39.5001 38.1 38.5001 37.3 37.9001 36.2C37.3001 35 37.3001 33.7 37.9001 32.5C38.2001 31.9 38.2001 31.2 37.9001 30.6L29.6001 14.2C29.2001 13.4 28.4001 12.9 27.5001 12.9C26.6001 12.9 25.8001 13.4 25.4001 14.2L2.10007 60.9L0.300049 64.5H8.20007L8.30005 64.2L24.0001 33C24.4001 32.4 24.4001 31.7 24.2001 31.1Z"
        fill="url(#paint1_linear_23_296)"
      />
      <path
        d="M73.6001 9.89999C72.4001 9.19999 71.5001 7.8 71.5001 6.3C71.5001 4.6 72.5001 3.2 73.9001 2.5H66.1001V64.4H73.9001V10.5C74.0001 10.1 73.6001 9.89999 73.6001 9.89999Z"
        fill="url(#paint2_linear_23_296)"
      />
      <path
        d="M85.2002 2.5H77.4001C78.8001 3.2 79.8001 4.6 79.8001 6.3C79.8001 7.8 79.0001 9.19999 77.8001 9.89999C77.8001 9.89999 77.4001 10.1 77.4001 10.5V64.4H85.2002V2.5Z"
        fill="url(#paint3_linear_23_296)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_23_296"
          x1="15.1637"
          y1="45.9085"
          x2="65.1635"
          y2="69.575"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#004EA2" />
          <stop offset="0.4892" stopColor="#008CD6" />
          <stop offset="1" stopColor="#00ADB2" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_23_296"
          x1="20.7787"
          y1="34.0449"
          x2="70.7799"
          y2="57.712"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#004EA2" />
          <stop offset="0.4892" stopColor="#008CD6" />
          <stop offset="1" stopColor="#00ADB2" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_23_296"
          x1="30.0927"
          y1="14.5362"
          x2="80.0338"
          y2="38.1747"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#004EA2" />
          <stop offset="0.4892" stopColor="#008CD6" />
          <stop offset="1" stopColor="#00ADB2" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_23_296"
          x1="32.1231"
          y1="10.1817"
          x2="82.0644"
          y2="33.8205"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#004EA2" />
          <stop offset="0.4892" stopColor="#008CD6" />
          <stop offset="1" stopColor="#00ADB2" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default Logo;
