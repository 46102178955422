import styled from "styled-components";

export const AlignCenter = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 0 3.125rem;
  gap: 3.125rem;
`;

export const Download = styled.a`
  padding: 10px 15px;
  border-radius: 8px;
  border: 1px solid #008cd6;
  color: #008cd6;
  font-weight: 700;
  cursor: pointer;
  &:hover {
    background-color: #008cd6;
    color: #fff;
  }
`;
