import styled from "styled-components";

const LayoutStyle = styled.main`
  margin-top: 75px;
  overflow-x: hidden;
  width: 100%;
`;

export const ElementWrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto 12.5rem;
  @media screen and (max-width: 1100px) {
    max-width: 800px;
  }
  @media screen and (max-width: 900px) {
    margin: 0 20px 12.5rem;
  }
`;

export const Heading = styled.h1`
  padding: 1rem 0;
  font-size: 1.5rem;
  margin: 1.563rem 0 2.5rem;
  border-bottom: 1px solid #ddd;
  font-weight: 700;
`;

export const AlignCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Highligth = styled.span`
  color: #008cd6;
`;

export const Emphatic = styled.b`
  font-weight: 700;
`;

export const Img = styled.img`
  width: 100%;
  padding: 1.25rem 0;
`;

export const ListWrapper = styled.ul`
  list-style: inside;
  line-height: 30px;
`;

export const Title = styled.h2`
  font-size: 1.25rem;
  vertical-align: middle;
  font-weight: 700;
  margin: 1.25rem 0;
  color: #008cd6;
`;

export const Files = styled.section`
  width: 100%;
  background-color: #f4f4f4;
  padding: 5px 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  h2 {
    padding-right: 20px;
  }
  @media screen and (max-width: 900px) {
    flex-direction: column;
    h2 {
      padding-bottom: 5px;
    }
  }
`;

export default LayoutStyle;
