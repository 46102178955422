import React from "react";
import { ElementWrapper, Emphatic, Heading, Img, Title } from "style/layout";
import road2 from "assets/png/road2.png";
import { PWrapper, Article } from "./style";
import List from "components/list";
import { Map, MapInfoWindow } from "react-kakao-maps-sdk";

function Road(): JSX.Element {
  return (
    <ElementWrapper>
      <Heading>오시는 길</Heading>
      <Article>
        <Map
          center={{
            lat: 35.14219804985941,
            lng: 126.92393007608248,
          }}
          style={{ width: "100%", height: "360px" }}
        >
          <MapInfoWindow
            removable={true}
            position={{
              lat: 35.14219804985941,
              lng: 126.92393007608248,
            }}
          >
            <div
              style={{
                boxSizing: "border-box",
                padding: "10px",
                textAlign: "center",
              }}
            >
              <Emphatic>
                전남대학교병원
                <br />
                의생명연구지원센터
              </Emphatic>
              <div style={{ color: "#000" }}></div>
            </div>
          </MapInfoWindow>
        </Map>
        <PWrapper>
          <p>
            광주광역시 동구 백서로 160 전남대학교병원 의생명연구지원센터 4층
            <br />
            <br />
            AI헬스케어의료기기상용화지원센터
          </p>
        </PWrapper>
      </Article>
      <Article>
        <Title>버스노선안내</Title>
        <List
          title="1번 승강장 - 남동성당 앞(승강장이름 : 전남대병원 오거리)"
          contents={[
            "봉선37, 지원45, 수완12, 수완49, 운림51, 지원52, 운림54, 금남59, 첨단95, 228(지원151), 지원152",
          ]}
          isSub
        />
        <br />
        <List
          title="2번 승강장 - 남동성당 건너편(승강장이름 : 전남대병원 오거리)"
          contents={[
            "봉선37, 지원45, 수완12, 수완49, 운림51, 지원52, 운림54, 금남59, 첨단95, 228(지원151), 지원152",
          ]}
          isSub
        />
        <br />
        <List
          title="3번 승강장 - 광주지방병무청 앞(승강장이름 : 전남대병원/남광주시장)"
          contents={[
            "첨단09, 봉선37, 지원45, 수완12, 수완49, 운림51, 지원52, 운림54, 금남59, 첨단95, 228(지원151), 지원152",
          ]}
          isSub
        />
        <br />
        <List
          title="4번 승강장 - 응급의료센터 앞(승강장이름 : 전남대병원/남광주시장)"
          contents={[
            "첨단09, 봉선37, 지원45, 수완12, 수완49, 운림51, 지원52, 운림54, 금남59, 첨단95, 228(지원151), 지원152",
          ]}
          isSub
        />
        <br />
        <List
          title="5번 승강장 - 조선대학교 공과대학 건너편(승강장이름 : 조선대공과대학)"
          contents={[
            "순환01, 지원15, 봉선27, 일곡28, 운림35, 금호36, 수완03, 금남55, 진월17, 문흥80",
          ]}
          isSub
        />
        <br />
        <List
          title="6번 승강장 - 조선대학교 정문 앞(승강장이름 : 조선대)"
          contents={["순환01, 지원15, 봉선27, 운림35, 수완03, 진월17, 문흥80"]}
          isSub
        />
      </Article>
      <Article>
        <Title>지하철노선안내</Title>
        <Img src={road2} alt="지하철 노선도" />
        <PWrapper>
          <p>
            전남대학교병원은 1호선을 타고 남광주역에서 하차 후 4번 출구로
            나오시면 됩니다.
          </p>
        </PWrapper>
      </Article>
    </ElementWrapper>
  );
}

export default Road;
