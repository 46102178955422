import React from "react";
import * as S from "./style";
import img from "assets/png/sub01_01.png";
import { ElementWrapper, Heading } from "style/layout";

function Sub01_01() {
  return (
    <ElementWrapper>
      <Heading>인사말</Heading>
      <article>
        <S.ImgWrapper>
          <S.Img
            src={img}
            alt="전남대학교병원 AI 헬스케어 의료기기 상용화센터장 조형호 사진"
          />
        </S.ImgWrapper>
        <br />
        <br />
        <br />
        <S.P>
          <S.Highligth>
            <S.Emphatic>
              전남대학교병원 AI 헬스케어 의료기기 상용화지원센터에 오신 것을
              환영합니다.
            </S.Emphatic>
          </S.Highligth>
          <br /> 전남대학교병원은 2022년도 신규 지역 거점
          기관지원사업(산업통상자원부)인 ‘병원중심 AI기반 디지털생체의료산업
          고도화사업’에 선정되어 AI 융복합 최첨단의료산업을 선도하고자 AI
          헬스케어 의료기기 상용화지원센터를 구축하였습니다.
        </S.P>
        <br />
        <S.P>
          디지털생체의료산업은 광주광역시의 주요 전략 생체의료산업에 4차
          산업혁명 관련기술(3D 프린팅, 빅데이터 분석, 인공지능 등)을 접목하여
          기존의 치과 정형외과용 소재부품, 광의료기기 등을 고도화 하는
          산업입니다.
        </S.P>
        <br />
        <S.P>
          이 사업에서 전남대학교병원은 광주테크노파크 및 전남대학교와 컨소시엄을
          구성하여 디지털 생체의료기기의 상용화 전주기 지원 시스템을
          구축하였습니다. 전남대학교병원은 임상의 연계 제품 개발과 실증 지원을
          주도하고, 광주테크노파크는 제품 고도화와 사업화을 지원하며,
          전남대학교는 성능 및 안전성 평가와 인허가 기술컨설팅을 지원합니다.
        </S.P>
        <br />
        <S.P>
          또한, 병원 내 구축된 AI-메디 인큐베이팅 시스템을 통한 병원-기업 간
          활발한 협업을 통해 광주광역시의 미래 선진의료산업을 이끌 수 있을
          것으로 기대하고 있습니다. <br />
          AI 헬스케어 의료기기의 개발 및 상용화에 관심이 있는 기업과 의료인들의
          적극적인 참여와 성원을 부탁드립니다.
        </S.P>
        <br />
        <br />
        <S.Hi>
          <S.Emphatic>
            전남대학교병원 AI 헬스케어 의료기기 상용화지원센터장 조형호
          </S.Emphatic>
        </S.Hi>
      </article>
    </ElementWrapper>
  );
}

export default Sub01_01;
