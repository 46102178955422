import React, { useEffect, useState } from "react";
import {
  CompanyLogoBox,
  CompanySection,
  Logo,
  LogoBox,
  LogoWrapper,
  Title,
} from "./style";

import { ILogo } from "lib/dto";
import { getLogoList } from "lib/req";

const CompanyLogo: React.FC = () => {
  const [logoList, setData] = useState<ILogo[]>([]);
  useEffect(() => {
    getLogoList().then(res => {
      res && setData(res.data.logo);
    });
  }, []);
  return (
    <CompanySection>
      <CompanyLogoBox>
        <Title>참여 기업</Title>
        <LogoBox>
          {logoList.map(v => (
            <LogoWrapper>
              <Logo key={v.url} src={v.url} />
            </LogoWrapper>
          ))}
        </LogoBox>
      </CompanyLogoBox>
    </CompanySection>
  );
};

export default CompanyLogo;
