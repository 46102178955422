import { dataFormat } from "lib/date";
import React from "react";
import { Post } from "types";
import * as S from "./style";

interface TableProps {
  path: string;
  posts: Post[] | undefined;
  isCount?: boolean;
}

const Table: React.FC<TableProps> = ({ posts, path, isCount = true }) => {
  if (posts === undefined)
    return (
      <S.Table>
        <thead>
          <tr>
            <th>제목</th>
            <th>내용</th>
            <th>날짜</th>
            <th>조회수</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>데이터가 존재하지 않습니다.</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </S.Table>
    );
  return (
    <S.Table>
      <thead>
        <tr>
          <th style={{ width: "5%" }}>No</th>
          <th>제목</th>
          <th style={{ width: "12%" }}>첨부파일</th>
          <th style={{ width: "12%" }}>날짜</th>
          {isCount && <th style={{ width: "12%" }}>조회수</th>}
        </tr>
      </thead>
      <tbody>
        {posts.map(data => (
          <tr
            key={data.idx}
            onClick={() => global.location.assign(`${path}-detail/${data.idx}`)}
            style={{ cursor: "pointer" }}
          >
            <td style={{ textAlign: "center" }}>{data.idx}</td>
            <td>{data.title}</td>
            <td style={{ textAlign: "center" }}>{data.file.length}</td>
            <td style={{ textAlign: "center" }}>
              {dataFormat(new Date(Number(data.createdAt)))}
            </td>
            {isCount && (
              <td style={{ textAlign: "center" }}>
                {Math.floor(data.viewCount / 2)}
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </S.Table>
  );
};

interface TableBtnProps {
  preBtnOnClick: () => void;
  nextBtnOnClick: () => void;
  currentPage: number;
  totalPage: number;
}

export const TableBtn: React.FC<TableBtnProps> = ({
  preBtnOnClick,
  nextBtnOnClick,
  currentPage,
  totalPage,
}) => {
  return (
    <S.TableBtn>
      <button onClick={preBtnOnClick}>이전</button>
      <p>
        {currentPage}/{totalPage}
      </p>
      <button onClick={nextBtnOnClick}>다음</button>
    </S.TableBtn>
  );
};

export default Table;
