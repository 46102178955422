import { AlignCenter } from "style/layout";
import styled from "styled-components";

export const ImgWrapper = styled(AlignCenter)`
  flex-direction: column;
  width: 100%;
`;

export const Img = styled.img`
  width: 100%;
  padding: 1.25rem 0;
`;
