import { login } from "lib/req";
import React, { useState } from "react";
import Button from "style/button";
import Input from "style/input";
import { ElementWrapper, Heading } from "style/layout";

function Login(): JSX.Element {
  const [id, setId] = useState("");
  const [pwd, setId2] = useState("");
  return (
    <ElementWrapper>
      <Heading>관리자 로그인</Heading>
      <Input
        type="text"
        placeholder="아이디"
        onChange={e => setId(e.currentTarget.value)}
      />
      <Input
        placeholder="비밀번호"
        type="password"
        onChange={e => setId2(e.currentTarget.value)}
      />
      <Button onClick={() => login({ pwd, id })}>로그인</Button>
    </ElementWrapper>
  );
}

export default Login;
