import styled from "styled-components";

export const Title = styled.h2`
  font-size: 2.5rem;
  display: inline-block;
  margin: 1.25rem 0;
`;

export const Highligth = styled.span`
  color: #008cd6;
`;

export const Img = styled.img`
  height: 26.875rem;
`;

export const Emphatic = styled.b`
  font-weight: 700;
`;

export const P = styled.p`
  line-height: 1.875rem;
`;

export const Image = styled.div`
  display: flex;
  @media screen and (max-width: 900px) {
    display: block;
    div {
      width: 100%;
    }
  }
`;

export const ImgWrapper = styled.div`
  display: flex;
  justify-content: center;
  background-color: #d7d7d7;
  width: 100%;
  height: auto;
  img {
    height: 100%;
    width: 100%;
  }
`;

export const ImgText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1a4186;
  padding: 0 5rem;
  width: 50%;
  bottom: 0;
  color: white;
  font-weight: 500;
  text-align: center;
  @media screen and (max-width: 900px) {
    h2 {
      font-size: 1.5rem;
    }
  }
`;

export const Hi = styled.p`
  float: right;
  font-size: 1.2rem;
  border-bottom: 2px solid #bbb;
  padding: 2rem 0;
`;
