import React from "react";
import { ElementWrapper, Heading } from "style/layout";
import { Img } from "./style";
import overviewImg1 from "assets/png/img/사업 개요-01.jpg";

function Overview(): JSX.Element {
  return (
    <ElementWrapper>
      <Heading>사업개요</Heading>
      <Img src={overviewImg1} alt="사업 개요 이미지" />
    </ElementWrapper>
  );
}

export default Overview;
