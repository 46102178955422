import React, { useEffect, useState } from "react";
import { ElementWrapper, Heading } from "style/layout";
import Table, { TableBtn } from "components/table";
import { getNewsPage } from "lib/req";
import { INews } from "lib/dto";

function News(): JSX.Element {
  const [posts, setPosts] = useState<{
    news: INews[];
    totalPage: number;
  }>();
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    getNewsPage(currentPage).then(res => {
      if (res?.data?.news?.length) {
        setPosts(res.data);
        return;
      }
      alert(`${currentPage} 페이지가 존재하지않습니다.`);
      setCurrentPage(currentPage - 1);
    });
  }, [currentPage]);

  return (
    <ElementWrapper>
      <Heading>보도자료/뉴스</Heading>
      <Table
        path={"news"}
        posts={posts?.news.length === 0 ? undefined : posts?.news}
      />
      <TableBtn
        preBtnOnClick={() =>
          currentPage !== 1 && setCurrentPage(currentPage - 1)
        }
        nextBtnOnClick={() =>
          posts &&
          currentPage < posts.totalPage &&
          setCurrentPage(currentPage + 1)
        }
        currentPage={currentPage}
        totalPage={posts?.totalPage || 1}
      />
    </ElementWrapper>
  );
}

export default News;
