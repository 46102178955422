import styled from "styled-components";

export const ElementWrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  @media screen and (max-width: 1100px) {
    max-width: 800px;
  }
  @media screen and (max-width: 900px) {
    margin: 0 20px;
  }
`;

export const SlideContainer = styled.div`
  position: relative;
`;

export const MainImg = styled.div<{ length: number }>`
  display: flex;
  justify-content: start;
  width: ${({ length }) => length}00vw;
  transition: margin-left 1s;
  div {
    width: 100%;
  }
  img {
    height: 50vh;
    @media screen and (max-width: 900px) {
      height: 30vh;
    }
    @media screen and (max-width: 380px) {
      height: 20vh;
    }
    display: block;
    margin: 0 auto;
    border-radius: 5px;
  }
`;

export const SlideButtonContainer = styled.div`
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translate(-50%, 0%);
  display: flex;
  gap: 1rem;
`;

export const SlideButton = styled.button`
  width: 4rem;
  height: 4rem;
  padding: 0;
  border: 1px solid #222;
  border-radius: 5px;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
  }

  :hover {
    scale: 1.2;
    transition: scale 0.3s;
  }
`;
