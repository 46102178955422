import { ElementWrapper, Heading } from "style/layout";
import GroupImg from "assets/png/img/연혁-01.jpg";
import React from "react";
import { ImgWrapper } from "pages/group/style";

function History(): JSX.Element {
  return (
    <ElementWrapper>
      <Heading>연혁</Heading>
      <ImgWrapper>
        <img src={GroupImg} alt="연혁" />
      </ImgWrapper>
    </ElementWrapper>
  );
}

export default History;
