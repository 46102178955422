import Viewer from "components/viewer";
import { dataFormat } from "lib/date";
import { IEquipment } from "lib/dto";
import { getEquipment } from "lib/req";
import { isImg } from "lib/str";
import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { ElementWrapper, Heading } from "style/layout";
import * as S from "./style";

const UseDetail = () => {
  const [data, setData] = useState<IEquipment>();
  const [img, setImg] = useState<string[]>([]);
  const ref = useRef<HTMLDivElement>(null);

  const onClickButton = (i: number) => {
    if (!ref.current) return;
    ref.current.style.marginLeft = `-${i - 1}00vw`;
  };

  let { id } = useParams();

  useEffect(() => {
    getEquipment(Number(id)).then(res => {
      res && setData(res.data);
      res && setImg(res.data.file.filter(e => isImg(e)));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (data == null) return <>{data}</>;

  return (
    <>
      <S.ElementWrapper>
        <Heading>{data.title}</Heading>
        <time style={{ display: "block", textAlign: "right" }}>
          작성일 : {dataFormat(new Date(Number(data.createdAt)))}
        </time>
      </S.ElementWrapper>
      <S.SlideContainer>
        <S.MainImg length={img.length} ref={ref}>
          {img.map(e => (
            <div>
              <img
                key={e}
                src={e}
                onError={e => (e.currentTarget.style.display = "none")}
                alt="첨부자료"
              />
            </div>
          ))}
        </S.MainImg>
        <S.SlideButtonContainer>
          {img.map((e, i) => (
            <S.SlideButton onClick={() => onClickButton(i + 1)}>
              <img
                key={e}
                style={{ display: "block", margin: "0 auto", maxWidth: "100%" }}
                src={e}
                onError={e => (e.currentTarget.style.display = "none")}
                alt="첨부자료"
              />
            </S.SlideButton>
          ))}
        </S.SlideButtonContainer>
      </S.SlideContainer>
      <ElementWrapper>
        <br />
        <br />
        <Viewer content={data.content} />
      </ElementWrapper>
    </>
  );
};

export default UseDetail;
