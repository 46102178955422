/* eslint-disable @typescript-eslint/ban-ts-comment */
import modalController from "components/modal/modalController";
import { getFnQ, updateFnQ } from "lib/req";
import React, { useEffect, useState } from "react";

import ReactModal from "react-modal";
import Button from "style/button";
import Input, { Textarea } from "style/input";

export interface EditProfileProps {
  children: React.ReactNode;
  idx: number;
}

function QNAEditModal({ children, idx }: EditProfileProps): JSX.Element {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [openModal, closeModal] = modalController(setModalIsOpen);

  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");

  useEffect(() => {
    getFnQ(idx).then(res => {
      if (res) {
        setTitle(res.data.q);
        setContent(res.data.a);
      }
    });
  }, [idx]);

  async function create() {
    updateFnQ(idx, {
      q: title,
      a: content,
    }).then(() => closeModal());
  }

  return (
    <>
      <button role="none" onClick={openModal}>
        {children}
      </button>
      {/* @ts-ignore */}
      <ReactModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        ariaHideApp={false}
        contentLabel="Edit Modal"
      >
        <Input
          onChange={e => setTitle(e.currentTarget.value)}
          value={title}
          type="text"
        />
        <Textarea
          onChange={e => setContent(e.currentTarget.value)}
          value={content}
        />
        <Button onClick={() => create()}>저장</Button>
      </ReactModal>
    </>
  );
}

export default QNAEditModal;
