import Editor from "components/editor";
import { createNews, imageHandler } from "lib/req";
import React, { useState } from "react";
import Button from "style/button";
import Input from "style/input";
import { ElementWrapper, Heading } from "style/layout";
import styled from "styled-components";

const CreateWrapper = styled.section`
  margin-top: 30px;
`;

function NewsCreate() {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [file, setFile] = useState<FileList>();

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    if (e.target.files) setFile(e.target.files);
  };

  async function returnFileUrls() {
    const files: File[] = [];
    if (file) {
      for (let index = 0; index < file.length; index++) {
        files.push(file[index]);
      }
    }
    return await Promise.all(files.map(file => imageHandler(file)));
  }

  async function create() {
    returnFileUrls().then(files => {
      createNews({ title, content, file: files });
    });
  }

  return (
    <ElementWrapper>
      <Heading>뉴스/보도자료 글쓰기</Heading>
      <CreateWrapper>
        <Input
          onChange={e => setTitle(e.currentTarget.value)}
          placeholder="제목"
          type="text"
        />
        <Editor setContent={setContent} content={content} />
        <Input
          onChange={handleChange}
          placeholder="파일"
          multiple={true}
          type={"file"}
        />
        <Button onClick={() => create()}>저장</Button>
      </CreateWrapper>
    </ElementWrapper>
  );
}

export default NewsCreate;
