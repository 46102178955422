const defaultStyles: ReactModal.Styles = {
  overlay: {
    zIndex: 9999,
    position: "fixed",
    inset: "0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#00000033",
  },
  content: {
    zIndex: 3,
    overflow: "hidden",
    borderRadius: "15px",
    background: "#FFFFFF",
    boxSizing: "border-box",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "1.875rem",
    minHeight: "80%",
    maxHeight: "90vh",
    overflowY: "scroll",
    margin: "0 20px",
  },
};

export default defaultStyles;
