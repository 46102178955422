import styled from "styled-components";

export const PWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 3.125rem 1.875rem;
  background-color: #e6e6e6;
`;

export const Article = styled.article`
  padding: 1.875rem 0;
`;
