import React from "react";
import { Link } from "react-router-dom";
import * as S from "./style";

function KeyInformation(): JSX.Element {
  return (
    <S.KeyInformationWrapper>
      <S.KeyInformation>
        <S.Title>주요 안내 사항</S.Title>
        <S.ItemWrapper>
          <S.Item>
            <Link to="road">오시는 길</Link>
          </S.Item>
          <S.Item>
            <Link to="overview">사업안내</Link>
          </S.Item>
          <S.Item>
            <Link to="use-announcement">장비사용안내</Link>
          </S.Item>
        </S.ItemWrapper>
      </S.KeyInformation>
    </S.KeyInformationWrapper>
  );
}

export default KeyInformation;
