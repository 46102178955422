import React, { useEffect, useState } from "react";
import { ElementWrapper, Heading, ListWrapper, Title } from "style/layout";
import { ListBox, ListBoxWrapper } from "./style";
import { Link } from "react-router-dom";
import { getEquipmentPage } from "lib/req";
import { IEquipment } from "lib/dto";
import { TableBtn } from "components/table";

function Use(): JSX.Element {
  const [posts, setPosts] = useState<{
    equipment: IEquipment[];
    totalPage: number;
  }>();
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    getEquipmentPage(currentPage).then(res => res && setPosts(res.data));
  }, [currentPage]);

  return (
    <ElementWrapper>
      <Heading>구축 장비 목록</Heading>
      <ListWrapper>
        <ListBoxWrapper>
          {posts?.equipment.map(({ idx, title, thumbnail }) => (
            <ListBox
              key={idx}
              onClick={() => (window.location.href = `/use-detail/${idx}`)}
            >
              <Link to={`/use-detail/${idx}`}>
                <img src={thumbnail} alt={title} />
              </Link>
              <Title>{title}</Title>
            </ListBox>
          ))}
        </ListBoxWrapper>
        <TableBtn
          preBtnOnClick={() =>
            currentPage !== 1 && setCurrentPage(currentPage - 1)
          }
          nextBtnOnClick={() =>
            posts &&
            currentPage < posts.totalPage &&
            setCurrentPage(currentPage + 1)
          }
          currentPage={currentPage}
          totalPage={posts?.totalPage || 1}
        />
      </ListWrapper>
    </ElementWrapper>
  );
}

export default Use;
