import { ImgWrapper, Img } from "pages/platform/style";
import React from "react";
import { ElementWrapper, Files, Heading } from "style/layout";
import use from "assets/png/img/장비사용안내.png";
import usePee from "assets/png/img/장비사용료안내.png";

import file1 from "assets/hwp/장비사용신청서.hwp";
import file2 from "assets/hwp/장비사용료.hwp";

function UseAnnouncement(): JSX.Element {
  return (
    <ElementWrapper>
      <Heading>장비 사용 안내</Heading>

      <Files style={{ width: "35%", marginLeft: "41em" }}>
        <h2 style={{ fontWeight: "lighter" }}>첨부파일</h2>
        <article>
          <p style={{ padding: "5px 0" }}>
            <a href={file1}>장비 사용 신청서.hwp</a>
          </p>
          <p style={{ padding: "5px 0" }}>
            <a href={file2}>장비 사용료 및 입금 계좌.hwp</a>
          </p>
        </article>
      </Files>

      <p>&nbsp;</p>
      <p>&nbsp;</p>

      <h3 style={{ textAlign: "center", fontWeight: "900", fontSize: "2em" }}>
        &nbsp;장비 공동활용 및 대여 안내
      </h3>
      <p style={{ textAlign: "center" }}>&nbsp;</p>
      <p style={{ textAlign: "center" }}>
        AI헬스케어의료기기상용화지원센터는 병원 의료 빅데이터와 AI기술을 접목한
      </p>
      <p style={{ textAlign: "center" }}>
        &nbsp;의료기기 개발 활성화를 위해 장비 공동 활용 및 대여를 아래와 같이
        진행하오니 많은 관심과 참여 바랍니다
      </p>
      <p style={{ textAlign: "center" }}>&nbsp;</p>
      <h4>
        <span style={{ color: "hsl(210,75%,60%)" }}>
          <strong>신청 절차</strong>
        </span>
      </h4>
      <p>
        <span style={{ color: "rgb(102,102,102)" }}>
          1. 장비 활용 및 대여 시 첨부파일을 작성하여&nbsp;이메일(
        </span>
        <span style={{ color: "hsl(210,75%,60%)" }}>
          <strong>aihmd@naver.com</strong>
        </span>
        <span style={{ color: "rgb(102,102,102)" }}>)통해 접수</span>
      </p>
      <p>2. 검토 및 승인</p>
      <p>
        <span style={{ color: "rgb(102,102,102)" }}>
          3. 장비 활용 및 대여 가능&nbsp;일정 확인
        </span>
      </p>
      <p>
        <span style={{ color: "rgb(102,102,102)" }}>
          4. 장비 교육 후 장비 활용
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ color: "hsl(210,75%,60%)" }}>
          <strong>장비사용료 입금 계좌</strong>
        </span>
      </p>
      <p>광주은행 1107-020-422147 / 전남대학교병원(AI헬스케어장비이용)</p>
      <p>&nbsp;</p>
      <ImgWrapper style={{ flexDirection: "row" }}>
        <Img
          src={use}
          alt="AI 헬스케어 의료기기 상용화지원센터 장비ㆍ설비 구축"
          style={{ width: "50%" }}
        />
        <Img
          src={usePee}
          alt="AI 헬스케어 의료기기 상용화지원센터 장비ㆍ설비 구축"
          style={{ width: "50%" }}
        />
      </ImgWrapper>
    </ElementWrapper>
  );
}

export default UseAnnouncement;
