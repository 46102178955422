import React from "react";
import { ImgWrapper } from "pages/group/style";
import GroupImg from "assets/png/img/장비확충-02.jpg";
import { ElementWrapper, Heading } from "style/layout";

function Equipment(): JSX.Element {
  return (
    <ElementWrapper>
      <Heading>장비구축 계획</Heading>
      <ImgWrapper>
        <img src={GroupImg} alt="장비구축 계획" />
      </ImgWrapper>
    </ElementWrapper>
  );
}

export default Equipment;
