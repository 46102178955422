import { register } from "lib/req";
import React, { useState } from "react";
import Button from "style/button";
import Input from "style/input";
import { ElementWrapper, Heading } from "style/layout";

function Register(): JSX.Element {
  const [id, setId] = useState("");
  const [pwd, setId2] = useState("");
  const [nickname, setId3] = useState("");
  const [admin_pwd, setId4] = useState("");
  return (
    <ElementWrapper>
      <Heading>관리자 회원가입</Heading>
      <Input
        placeholder="아이디"
        type="text"
        onChange={e => setId(e.currentTarget.value)}
      />
      <Input
        placeholder="비밀번호"
        type="password"
        onChange={e => setId2(e.currentTarget.value)}
      />
      <Input
        placeholder="닉네임"
        type="text"
        onChange={e => setId3(e.currentTarget.value)}
      />
      <Input
        placeholder="관리자 비밀번호"
        type="text"
        onChange={e => setId4(e.currentTarget.value)}
      />
      <Button onClick={() => register({ id, pwd, nickname, admin_pwd })}>
        회원가입
      </Button>
    </ElementWrapper>
  );
}

export default Register;
