import { Emphatic, ListWrapper, Title } from "style/layout";
import React from "react";

interface ListProps {
  title: string;
  contents: string[];
  isSub?: boolean;
}

function List({ title, contents, isSub }: ListProps): JSX.Element {
  return (
    <ListWrapper>
      {isSub ? <Emphatic>{title}</Emphatic> : <Title>{title}</Title>}
      {contents.map(content => (
        <li>{content}</li>
      ))}
    </ListWrapper>
  );
}

export default List;
