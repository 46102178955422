import React from "react";
import { ElementWrapper, Heading } from "style/layout";
import platformImg1 from "assets/png/img/플랫폼구축-01.jpg";
import { Img, ImgWrapper } from "./style";

function Platform(): JSX.Element {
  return (
    <ElementWrapper>
      <Heading>플랫폼 구축</Heading>
      <ImgWrapper>
        <Img
          src={platformImg1}
          alt="AI 헬스케어 의료기기 상용화지원센터 장비ㆍ설비 구축"
        />
      </ImgWrapper>
    </ElementWrapper>
  );
}
export default Platform;
