import styled from "styled-components";

export const HeaderWrap = styled.header`
  position: absolute;
  z-index: 999;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  list-style: none;
  @media screen and (max-width: 780px) {
    & > nav {
      display: none;
    }
  }

  section div nav ul li {
    text-align: left;
  }

  & li {
    width: 11.25rem;
    text-align: center;
    cursor: pointer;
    text-align: left;
  }

  & section {
    margin: 0 auto;
    display: flex;
    justify-content: flex-end;

    max-width: 1000px;
    margin: 0 auto;
    @media screen and (max-width: 1100px) {
      max-width: 800px;
    }
    @media screen and (max-width: 900px) {
      margin: 0 20px;
    }
  }
  & section nav li {
    padding: 0.625rem 0;
    &:hover {
      font-weight: 700;
    }
  }

  & section nav {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  & > div {
    max-width: 1000px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.15rem;
    @media screen and (max-width: 1100px) {
      max-width: 800px;
    }
  }

  & > div > nav > ul {
    display: flex;
    width: 100%;
    justify-content: space-between;
    list-style: none;
    font-weight: 700;
    color: black;
    position: relative;
    padding: 1.7rem 0;
  }
`;

export const HeaderLi = styled.li`
  width: 11.25rem;
  text-align: center;
  cursor: pointer;
  &:after {
    transition: all ease-in-out 0.2s;
    background: none repeat scroll 0 0 #008cd6;
    content: "";
    display: block;
    height: 2px;
    width: 0;
  }
  &:hover:after {
    width: 50%;
    font-weight: 700;
  }
`;

export const HeaderLiHeaderSectionNavLi = styled.li`
  width: 11.25rem;
  text-align: center;
  cursor: pointer;
`;

export const HeaderSection = styled.section`
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;

  max-width: 1000px;
  margin: 0 auto;
  @media screen and (max-width: 1100px) {
    max-width: 800px;
  }
  @media screen and (max-width: 900px) {
    margin: 0 20px;
  }
`;

export const HeaderSectionNavLi = styled.li`
  padding: 0.625rem 0;
  &:hover {
    font-weight: 700;
  }
`;

export const HeaderSectionNav = styled.nav`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const HeaderDDiv = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 1100px) {
    max-width: 800px;
  }
`;

export const HeaderDNavDul = styled.ul`
  display: flex;
  width: 100%;
  justify-content: space-between;
  list-style: none;
  font-weight: 700;
  color: black;
  position: relative;
  padding: 1.7rem 0;
`;

///////////////////////////////////////////

export const ShowMenu = styled.div<{ isShow: boolean }>`
  ${({ isShow }) =>
    isShow
      ? `
  position: absolute;
  right: 0px;
  width: 100vw;
  background: #008cd6;
  border-top: #e6e6e6 solid 1px;`
      : `
  width: auto;
  position: absolute;
  display: none;`}

  & li {
    width: 100%;
  }

  /* & > nav {
    display: none;
    @media screen and (max-width: 780px) {
      display: block;
    }
  } */
`;

export const MobileNav = styled.nav`
  display: none;
  @media screen and (max-width: 780px) {
    display: block;
  }
`;

export const ShowMenuLi = styled.li`
  width: 100%;
`;

export const MobileDNav = styled.nav`
  display: none;
  @media screen and (max-width: 780px) {
    display: block;
  }
`;
