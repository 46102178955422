/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Button from "style/button";
import "./style.css";
import img from "assets/png/btn_sitemap.gif";
import { MobileNav, ShowMenu } from "./style";

const MobileHeader = () => {
  const [isOpen, setMenu] = useState(false);

  const toggleMenu = () => {
    setMenu(isOpen => !isOpen);
    setMenu2(false);
    setMenu3(false);
    setMenu4(false);
  };
  const [isOpen2, setMenu2] = useState(false);

  const toggleMenu2 = () => {
    setMenu2(isOpen => !isOpen);
    setMenu(false);
    setMenu3(false);
    setMenu4(false);
  };
  const [isOpen3, setMenu3] = useState(false);

  const toggleMenu3 = () => {
    setMenu3(isOpen => !isOpen);
    setMenu2(false);
    setMenu(false);
    setMenu4(false);
  };
  const [isOpen4, setMenu4] = useState(false);

  const toggleMenu4 = () => {
    setMenu4(isOpen => !isOpen);
    setMenu2(false);
    setMenu3(false);
    setMenu(false);
  };
  const [isOpen5, setMenu5] = useState(false);

  const toggleMenu5 = () => {
    setMenu5(isOpen => !isOpen);
    setMenu2(false);
    setMenu3(false);
    setMenu(false);
    setMenu4(false);
  };

  const reset = () => {
    setMenu5(false);
    setMenu2(false);
    setMenu3(false);
    setMenu(false);
    setMenu4(false);
  };

  return (
    <MobileNav className="mobile--nav" style={{ position: "relative" }}>
      <img
        style={{ padding: "1.7rem 0" }}
        onClick={() => toggleMenu5()}
        src={img}
        alt="메뉴"
      />
      <ShowMenu isShow={isOpen5}>
        <ul className="header-wrapper">
          <li className="show-menu-li">
            <Button onClick={() => toggleMenu()}>센터소개</Button>
          </li>
        </ul>
        <ShowMenu isShow={isOpen}>
          <li className="show-menu-li">
            <Link onClick={() => reset()} to="sub01_01">
              <Button>인사말</Button>
            </Link>
          </li>
          <li className="show-menu-li">
            <Link onClick={() => reset()} to="vision">
              <Button>비전 및 목표</Button>
            </Link>
          </li>
          <li className="show-menu-li">
            <Link onClick={() => reset()} to="history">
              <Button>연혁</Button>
            </Link>
          </li>
          <li className="show-menu-li">
            <Link onClick={() => reset()} to="group">
              <Button>조직현황</Button>
            </Link>
          </li>
          <li className="show-menu-li">
            <Link onClick={() => reset()} to="CI">
              <Button>CI</Button>
            </Link>
          </li>
          <li className="show-menu-li">
            <Link onClick={() => reset()} to="road">
              <Button>오시는 길</Button>
            </Link>
          </li>
        </ShowMenu>
        <ul className="header-wrapper">
          <li className="show-menu-li">
            <Button onClick={() => toggleMenu2()}>사업안내</Button>
          </li>
        </ul>
        <ShowMenu isShow={isOpen2}>
          <li className="show-menu-li">
            <Link onClick={() => reset()} to="overview">
              <Button>사업개요</Button>
            </Link>
          </li>
          <li className="show-menu-li">
            <Link onClick={() => reset()} to="platform">
              <Button>플랫폼구축</Button>
            </Link>
          </li>
          <li className="show-menu-li">
            <Link onClick={() => reset()} to="skill">
              <Button>임상의 연계 기술 지원</Button>
            </Link>
          </li>
          <li className="show-menu-li">
            <Link onClick={() => reset()} to="training">
              <Button>전문 인력 양성</Button>
            </Link>
          </li>
        </ShowMenu>
        <ul className="header-wrapper">
          <li className="show-menu-li">
            <Button onClick={() => toggleMenu3()}>장비활용</Button>
          </li>
        </ul>
        <ShowMenu isShow={isOpen3}>
          <li className="show-menu-li">
            <Link onClick={() => reset()} to="equipment">
              <Button>장비구축계획</Button>
            </Link>
          </li>
          <li className="show-menu-li">
            <Link onClick={() => reset()} to="use">
              <Button>구축장비목록</Button>
            </Link>
          </li>
          <li className="show-menu-li">
            <Link onClick={() => reset()} to="use-announcement">
              <Button>장비사용안내</Button>
            </Link>
          </li>
        </ShowMenu>
        <ul className="header-wrapper">
          <li className="show-menu-li">
            <Button onClick={() => toggleMenu4()}>커뮤니티</Button>
          </li>
        </ul>
        <ShowMenu isShow={isOpen4}>
          <li className="show-menu-li">
            <Link onClick={() => reset()} to="notice">
              <Button>공지사항</Button>
            </Link>
          </li>
          <li className="show-menu-li">
            <Link onClick={() => reset()} to="news">
              <Button>보도자료/뉴스</Button>
            </Link>
          </li>
          <li className="show-menu-li">
            <Link onClick={() => reset()} to="qna">
              <Button>자주 묻는 질문</Button>
            </Link>
          </li>
        </ShowMenu>
      </ShowMenu>
    </MobileNav>
  );
};

export default MobileHeader;
