import styled from "styled-components";

export const Logo = styled.img`
  width: 4.313rem;
  max-height: 100%;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
`;
export const LogoWrapper = styled.div`
  width: 5.5rem;
  height: 3.938rem;

  border: 1px solid #efefef;
  box-shadow: 3px 3px 5px #bbbbbb;

  text-align: center;
  position: relative;
`;

export const Title = styled.h2`
  font-size: 1.25rem;
  font-weight: 700;
  margin: 0.625rem 0;
`;

export const CompanySection = styled.section`
  padding: 1.875rem 0;
`;

export const CompanyLogoBox = styled.div`
  align-items: center;
  margin: 0 auto;
  background-color: white;
  max-width: 1000px;
  @media screen and (max-width: 1100px) {
    max-width: 800px;
  }
  @media screen and (max-width: 900px) {
    margin: 0 20px;
  }
`;
export const LogoBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  justify-content: center;
`;
