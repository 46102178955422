import React from "react";
import Header from "components/header";
import Footer from "./components/footer";
import Main from "pages/main";
import Sub01 from "pages/sub01_01";
import Vision from "pages/vision";
import { Toaster } from "react-hot-toast";
import { Routes, Route } from "react-router-dom";
import LayoutStyle from "style/layout";
import History from "pages/history";
import Group from "pages/group";
import CI from "pages/CI";
import Overview from "pages/overview";
import Platform from "pages/platform";
import Skill from "pages/skill";
import Equipment from "pages/equipment";
import Training from "pages/training";
import QNA from "pages/qna";
import UseAnnouncement from "pages/use-announcement";
import Use from "pages/use";
import Road from "pages/road";
import News from "pages/news";
import Notice from "pages/notice";
import Admin from "pages/admin";
import Login from "pages/admin/login";
import Register from "pages/admin/register";
import ReactModal from "react-modal";
import defaultStyles from "style/modal";
import NewsDetail from "pages/detail";
import NewsCreate from "pages/newsCreate";
import NoticeDetail from "pages/noticeDetail";
import NoticeCreate from "pages/noticeCreate";
import UseDetail from "pages/use/detail";
import QnaCreate from "pages/qna/create";
import UseCreate from "pages/use/create";
import Banner from "components/banner";

ReactModal.setAppElement("#root");
ReactModal.defaultStyles = defaultStyles;

function App() {
  return (
    <>
      <Header />
      <LayoutStyle>
        <Banner />
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="sub01_01" element={<Sub01 />} />
          <Route path="vision" element={<Vision />} />
          <Route path="history" element={<History />} />
          <Route path="group" element={<Group />} />
          <Route path="CI" element={<CI />} />
          <Route path="overview" element={<Overview />} />
          <Route path="platform" element={<Platform />} />
          <Route path="skill" element={<Skill />} />
          <Route path="equipment" element={<Equipment />} />
          <Route path="training" element={<Training />} />
          <Route path="qna" element={<QNA />} />
          <Route path="use-announcement" element={<UseAnnouncement />} />
          <Route path="use" element={<Use />} />
          <Route path="road" element={<Road />} />
          <Route path="news" element={<News />} />
          <Route path="notice" element={<Notice />} />
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path="admin" element={<Admin />} />
          <Route path="*" element={<Main />} />
          <Route path="use-detail/:id" element={<UseDetail />} />
          <Route path="news-detail/:id" element={<NewsDetail />} />
          <Route path="notice-detail/:id" element={<NoticeDetail />} />
          <Route path="use-create" element={<UseCreate />} />
          <Route path="qna-create" element={<QnaCreate />} />
          <Route path="news-create" element={<NewsCreate />} />
          <Route path="notice-create" element={<NoticeCreate />} />
        </Routes>
        <Toaster />
      </LayoutStyle>
      <Footer />
    </>
  );
}

export default App;
