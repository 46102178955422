import EditModal from "components/modal/edit";
import NewsEditModal from "components/modal/newsEdit";
import UseEditModal from "pages/use/edit";
import React from "react";
import Button from "style/button";
import { Post } from "types";
import * as S from "./style";

interface TableProps {
  path: string;
  posts: Post[] | undefined;
  onDelete: (idx: number) => void;
  isCount?: boolean;
}

const ManagementTable: React.FC<TableProps> = ({
  posts,
  onDelete,
  path,
  isCount = true,
}) => {
  if (posts === undefined)
    return (
      <>
        <S.Table>
          <thead>
            <tr>
              <th>제목</th>
              <th>내용</th>
              <th>날짜</th>
              {isCount && <th>조회수</th>}
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>데이터가 존재하지 않습니다.</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              {isCount && <td></td>}
            </tr>
          </tbody>
        </S.Table>
        <Button onClick={() => global.location.assign(`${path}-create`)}>
          글쓰기
        </Button>
      </>
    );
  return (
    <>
      <S.Table>
        <thead>
          <tr>
            <th style={{ width: "5%", textAlign: "center" }}>No</th>
            <th>제목</th>
            <th style={{ width: "12%" }}>날짜</th>
            {isCount && (
              <th style={{ width: "12%", textAlign: "center" }}>조회수</th>
            )}
            <th style={{ width: "8%" }} />
            <th style={{ width: "8%" }} />
          </tr>
        </thead>
        <tbody>
          {posts.map(data => (
            <tr key={data.idx}>
              <td style={{ textAlign: "center" }}>{data.idx}</td>
              <td
                onClick={() =>
                  global.location.assign(`${path}-detail/${data.idx}`)
                }
                style={{ cursor: "pointer" }}
              >
                {data.title}
              </td>
              <td>
                {new Date(Number(data.createdAt)).getFullYear()}.
                {new Date(Number(data.createdAt)).getMonth() + 1}.
                {new Date(Number(data.createdAt)).getDate()}
              </td>
              {isCount && (
                <td style={{ textAlign: "center" }}>
                  {Math.floor(data.viewCount / 2)}
                </td>
              )}
              <td>
                {path === "news" ? (
                  <NewsEditModal idx={data.idx}>수정</NewsEditModal>
                ) : path === "notice" ? (
                  <EditModal idx={data.idx}>수정</EditModal>
                ) : (
                  <UseEditModal idx={data.idx}>수정</UseEditModal>
                )}
              </td>
              <td>
                <button onClick={() => onDelete(data.idx)}>삭제</button>
              </td>
            </tr>
          ))}
        </tbody>
      </S.Table>
      <Button onClick={() => global.location.assign(`${path}-create`)}>
        글쓰기
      </Button>
    </>
  );
};

interface TableBtnProps {
  preBtnOnClick: () => void;
  nextBtnOnClick: () => void;
  currentPage: number;
  totalPage: number;
}

export const TableBtn: React.FC<TableBtnProps> = ({
  preBtnOnClick,
  nextBtnOnClick,
  currentPage,
  totalPage,
}) => {
  return (
    <S.TableBtn>
      <button onClick={preBtnOnClick}>이전</button>
      <p>
        {currentPage}/{totalPage}
      </p>
      <button onClick={nextBtnOnClick}>다음</button>
    </S.TableBtn>
  );
};

export default ManagementTable;
