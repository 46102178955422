/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Logo, LogoWrapper } from "components/companyLogo/style";
import modalController from "components/modal/modalController";
import { imageHandler, getLogoList, appendLogo, removeLogo } from "lib/req";
import React, { useEffect, useState } from "react";

import ReactModal from "react-modal";
import Button from "style/button";
import Input from "style/input";
import styled from "styled-components";

const LogoBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

export interface EditProfileProps {
  children: React.ReactNode;
}

interface ILogo {
  url: string;
  idx: number;
}

function LogoEditModal({ children }: EditProfileProps): JSX.Element {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [openModal, closeModal] = modalController(setModalIsOpen);

  const [filesUrl, setFilesUrl] = useState<ILogo[]>();

  const [file, setFile] = useState<FileList>();

  useEffect(() => {
    getLogoList().then(res => res && setFilesUrl(res.data.logo));
  }, []);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    if (e.target.files) setFile(e.target.files);
  };

  async function returnFileUrls() {
    const files: File[] = [];
    if (file) {
      for (let index = 0; index < file.length; index++) {
        files.push(file[index]);
      }
    }
    return await Promise.all(files.map(file => imageHandler(file)));
  }

  async function create() {
    // 업데이트 로직
    returnFileUrls().then(files => {
      Promise.all(files.map(url => appendLogo(url)))
        .then(() => closeModal())
        .catch(_ => {
          console.error("이미지 업로딩 실패, 재시도 바랍니다.");
          closeModal();
        });
    });
  }

  return (
    <>
      <Button role="none" onClick={openModal}>
        {children}
      </Button>
      {/* @ts-ignore */}
      <ReactModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        ariaHideApp={false}
        contentLabel="Edit Modal"
      >
        <div
          style={{
            border: "1px solid #dfdfdf",
            padding: "0.625rem",
            margin: "0.625rem 0",
            width: "100%",
          }}
        >
          <h2>기존 로고</h2>
          <br />
          <LogoBox>
            {filesUrl &&
              filesUrl.map(e => (
                <p key={e.idx}>
                  <LogoWrapper>
                    <Logo src={e.url} alt={"참여기업로고"} />
                  </LogoWrapper>
                  <Button
                    onClick={() => {
                      removeLogo(e.idx).then();
                    }}
                  >
                    삭제
                  </Button>
                </p>
              ))}
          </LogoBox>
        </div>
        <label style={{ width: "100%" }}>
          새롭게 추가할 로고
          <Input onChange={handleChange} multiple={true} type={"file"} />
        </label>
        <Button onClick={() => create()}>저장</Button>
      </ReactModal>
    </>
  );
}

export default LogoEditModal;
