import styled from "styled-components";

export const KeyInformationWrapper = styled.section`
  padding: 1.875rem 0;
`;

export const KeyInformation = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  background-color: white;
  max-width: 1000px;
  margin: 0 auto;
  @media screen and (max-width: 1100px) {
    max-width: 800px;
  }
  @media screen and (max-width: 900px) {
    margin: 0 20px;
  }
`;

export const Title = styled.h2`
  font-size: 1.25rem;
  flex: 1;
  font-weight: 700;
  @media screen and (max-width: 450px) {
    display: none;
  }
`;

export const Item = styled.article`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  font-size: 1.125rem;
  padding: 0.625rem 0;
  border-left: 1px solid #c4c4c4;
  flex: 1;
  cursor: pointer;
  &:first-child {
    border-left: none;
  }
`;

export const ItemWrapper = styled.div`
  display: flex;
  flex: 3;
`;
