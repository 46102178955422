import axios from "axios";
import errorCatch from "./error";

const accessToken: string = localStorage.getItem("accessToken") ?? "";
const refreshToken: string = localStorage.getItem("refreshToken") ?? "";
const accountId: string = localStorage.getItem("accountId") ?? "";

const instance = axios.create({
  baseURL: "https://api.aihmd.or.kr/hc/",
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,POST,DELETE,PATCH,PUT,OPTIONS",
    "Access-Control-Allow-Headers":
      "Origin, X-Requested-With, Content-Type, Accept, Authorization, Access-Control-Allow-Credentials, X-Authentication-Token",
    "x-access-token": accessToken,
    "x-refresh-token": refreshToken,
    "X-Authorization-Id": accountId,
  },
  withCredentials: true,
});
instance.interceptors.response.use(v => v, errorCatch);
export default instance;
