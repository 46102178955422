import styled from "styled-components";

const Input = styled.input`
  border: 1px solid #dfdfdf;
  padding: 0.625rem;
  width: 100%;
  margin-bottom: 0.625rem;
  box-sizing: border-box;
`;

export const Textarea = styled.textarea`
  border: 1px solid #dfdfdf;
  padding: 0.625rem;
  width: 100%;
  margin-bottom: 0.625rem;
  box-sizing: border-box;
  resize: vertical;
  min-height: 50vh;
  resize: none;
`;

export default Input;
