import React from "react";
import Notice from "components/notice";
import KeyInformation from "components/keyInformation";
import CompanyLogo from "components/companyLogo";

function Main() {
  return (
    <main>
      <KeyInformation />
      <Notice />
      <CompanyLogo />
    </main>
  );
}

export default Main;
