//@ts-ignore
import CustomEditor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import React from "react";
import "components/editor/style.css";
import styled from "styled-components";

const RemoveToolbar = styled.div`
  & .ck .ck-toolbar__items {
    display: none;
  }
  .ck-read-only {
    border: none;
  }
  .ck.ck-content {
    min-height: 10vh;
    width: calc(100% - 2px);
  }
`;

const Viewer: React.FC<{ content: string }> = ({ content }) => {
  return (
    <RemoveToolbar>
      <CKEditor editor={CustomEditor} data={content} disabled />
    </RemoveToolbar>
  );
};

export default Viewer;
