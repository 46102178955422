import Logo from "assets/svg/logo";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import MobileHeader from "./mobile";
import * as S from "./style";

function Header(): JSX.Element {
  const [isVisible, setIsVisible] = useState(false);
  return (
    <S.HeaderWrap className="header">
      <div className="header--div">
        <Link className="header--div--a" to="/">
          <Logo width={180} height={35} />
        </Link>
        <nav
          onMouseOver={() => setIsVisible(true)}
          onMouseOut={() => setIsVisible(false)}
          className="header--nav"
        >
          <ul className="header--nav--ul">
            <S.HeaderLi className="">센터소개</S.HeaderLi>
            <S.HeaderLi className="">사업안내</S.HeaderLi>
            <S.HeaderLi className="">장비활용</S.HeaderLi>
            <S.HeaderLi className="">커뮤니티</S.HeaderLi>
          </ul>
        </nav>
        <MobileHeader />
      </div>
      <S.HeaderSection
        onMouseOver={() => setIsVisible(true)}
        onMouseOut={() => setIsVisible(false)}
        style={isVisible ? { display: "flex" } : { display: "none" }}
        className={`header-section`}
      >
        <div>
          <nav className="header-section-nav">
            <ul>
              <li className=" header-section-nav-li">
                <Link to="sub01_01">인사말</Link>
              </li>
              <li className=" header-section-nav-li">
                <Link to="vision">비전 및 목표</Link>
              </li>
              <li className=" header-section-nav-li">
                <Link to="history">연혁</Link>
              </li>
              <li className=" header-section-nav-li">
                <Link to="group">조직현황</Link>
              </li>
              <li className=" header-section-nav-li">
                <Link to="CI">CI</Link>
              </li>
              <li className=" header-section-nav-li">
                <Link to="road">오시는 길</Link>
              </li>
            </ul>
            <ul>
              <li className=" header-section-nav-li">
                <Link to="overview">사업개요</Link>
              </li>
              <li className=" header-section-nav-li">
                <Link to="platform">플랫폼구축</Link>
              </li>
              <li className=" header-section-nav-li">
                <Link to="skill">임상의 연계 기술 지원</Link>
              </li>
              <li className=" header-section-nav-li">
                <Link to="training">전문 인력 양성</Link>
              </li>
            </ul>
            <ul>
              <li className=" header-section-nav-li">
                <Link to="equipment">장비구축계획</Link>
              </li>
              <li className=" header-section-nav-li">
                <Link to="use">구축장비목록</Link>
              </li>
              <li className=" header-section-nav-li">
                <Link to="use-announcement">장비사용안내</Link>
              </li>
            </ul>
            <ul>
              <li className=" header-section-nav-li">
                <Link to="notice">공지사항</Link>
              </li>
              <li className=" header-section-nav-li">
                <Link to="news">보도자료/뉴스</Link>
              </li>
              <li className=" header-section-nav-li">
                <Link to="qna">자주 묻는 질문</Link>
              </li>
            </ul>
          </nav>
        </div>
      </S.HeaderSection>
    </S.HeaderWrap>
  );
}

export default Header;
