import GroupImg from "assets/png/img/임상의 연계 기술 지원-01.jpg";
import { ElementWrapper, Heading, Img } from "style/layout";

function Skill(): JSX.Element {
  return (
    <ElementWrapper>
      <Heading>임상의 연계 기술 지원</Heading>
      <Img src={GroupImg} alt="기술지원 과정" />
    </ElementWrapper>
  );
}

export default Skill;
