import axios, { AxiosResponse } from "axios";
import instance from "./axois";
import {
  IAnnouncement,
  IEquipment,
  IFnQ,
  ILogin,
  ILogo,
  INews,
  IPostAccount,
  ITokens,
  IWriteAnnouncement,
  IWriteEquipment,
  IWriteFnQ,
  IWriteNews,
} from "./dto";

type TAxiosResponse<T> = AxiosResponse<T, any> | void;

export async function getAnnouncement(
  idx: number
): Promise<TAxiosResponse<IAnnouncement>> {
  return instance.get<IAnnouncement>(`announcement/${idx}`);
}

export async function getAnnouncementPage(
  page: number
): Promise<
  TAxiosResponse<{ announcement: IAnnouncement[]; totalPage: number }>
> {
  return instance.get(`announcement`, {
    params: {
      page: page,
    },
  });
}

export async function createAnnouncement(
  post: IWriteAnnouncement
): Promise<TAxiosResponse<IAnnouncement>> {
  return instance
    .post(`announcement`, { ...post })
    .then(() => global.location.replace(`/admin`));
}

export async function updateAnnouncement(
  idx: number,
  data: Partial<IWriteAnnouncement>
): Promise<TAxiosResponse<unknown>> {
  return instance
    .patch(`announcement/${idx}`, { ...data })
    .then(() => global.location.reload());
}

export async function deleteAnnouncement(
  idx: number
): Promise<TAxiosResponse<unknown>> {
  return instance
    .delete(`announcement/${idx}`)
    .then(() => global.location.reload());
}

export async function getNews(idx: number): Promise<TAxiosResponse<INews>> {
  return instance.get(`news/${idx}`);
}

export async function getNewsPage(
  page: number
): Promise<TAxiosResponse<{ news: INews[]; totalPage: number }>> {
  return instance.get(`news`, {
    params: {
      page: page,
    },
  });
}

export async function createNews(
  post: IWriteNews
): Promise<TAxiosResponse<INews>> {
  return instance
    .post(`news`, { ...post })
    .then(() => global.location.replace(`/admin`));
}

export async function updateNews(
  idx: number,
  data: Partial<IWriteNews>
): Promise<TAxiosResponse<unknown>> {
  return instance
    .patch(`news/${idx}`, { ...data })
    .then(() => global.location.reload());
}

export async function deleteNews(
  idx: number
): Promise<TAxiosResponse<unknown>> {
  return instance.delete(`news/${idx}`).then(() => global.location.reload());
}

export async function getEquipment(
  idx: number
): Promise<TAxiosResponse<IEquipment>> {
  return instance.get<IEquipment>(`equipment/${idx}`);
}

export async function getEquipmentPage(
  page: number
): Promise<TAxiosResponse<{ equipment: IEquipment[]; totalPage: number }>> {
  return instance.get(`equipment`, {
    params: {
      page: page,
    },
  });
}

export async function createEquipment(
  post: IWriteEquipment
): Promise<TAxiosResponse<IEquipment>> {
  return instance
    .post(`equipment`, { ...post })
    .then(() => global.location.replace(`/admin`));
}

export async function updateEquipment(
  idx: number,
  data: Partial<IWriteEquipment>
): Promise<TAxiosResponse<unknown>> {
  return instance
    .patch(`equipment/${idx}`, { ...data })
    .then(() => global.location.reload());
}

export async function deleteEquipment(
  idx: number
): Promise<TAxiosResponse<unknown>> {
  return instance
    .delete(`equipment/${idx}`)
    .then(() => global.location.reload());
}

export async function getFnQ(idx: number): Promise<TAxiosResponse<IFnQ>> {
  return instance.get<IFnQ>(`fnq/${idx}`);
}

export async function getFnQPage(
  page: number
): Promise<TAxiosResponse<{ fnq: IFnQ[]; totalPage: number }>> {
  return instance.get(`fnq`, {
    params: {
      page: page,
    },
  });
}

export async function createFnQ(
  post: IWriteFnQ
): Promise<TAxiosResponse<IFnQ>> {
  return instance
    .post(`fnq`, { ...post })
    .then(() => global.location.replace(`/admin`));
}

export async function updateFnQ(
  idx: number,
  data: Partial<IWriteFnQ>
): Promise<TAxiosResponse<unknown>> {
  return instance
    .patch(`fnq/${idx}`, { ...data })
    .then(() => global.location.reload());
}

export async function deleteFnQ(idx: number): Promise<TAxiosResponse<unknown>> {
  return instance.delete(`fnq/${idx}`).then(() => global.location.reload());
}

export async function getLogoList(): Promise<
  TAxiosResponse<{ logo: ILogo[] }>
> {
  return instance.get(`logo`, {});
}

export async function appendLogo(url: string): Promise<TAxiosResponse<ILogo>> {
  return instance
    .post(`logo`, { url })
    .then(() => global.location.replace(`/admin`));
}

export async function removeLogo(
  idx: number
): Promise<TAxiosResponse<unknown>> {
  return instance.delete(`logo/${idx}`).then(() => global.location.reload());
}

export async function login(data: ILogin): Promise<TAxiosResponse<ITokens>> {
  return instance
    .post<{
      accessToken: string;
      refreshToken: string;
    }>(`admin`, data)
    .then(res => {
      localStorage.setItem("accessToken", res.data.accessToken);
      localStorage.setItem("refreshToken", res.data.refreshToken);
      global.location.replace(`/admin`);
      return res;
    });
}

export async function register(
  data: IPostAccount
): Promise<TAxiosResponse<ITokens>> {
  return instance
    .post(`admin/account`, data)
    .then(() => global.location.replace(`/login`));
}

async function getPresignedUrl(_fileName: string) {
  const fileName = `${new Date().valueOf()}_${_fileName}`;
  const res = await instance.post(`file`, { name: fileName });
  return { url: res.data.url, fileName };
}

export const imageHandler = async (imageData: File) => {
  const { url, fileName } = await getPresignedUrl(imageData.name);
  await axios.put(url, imageData);
  return `https://healthc.s3.ap-northeast-2.amazonaws.com/${new Date().getFullYear()}/${fileName}`;
};

export function UploadAdapterPlugin(editor: any) {
  editor.plugins.get("FileRepository").createUploadAdapter = (
    loader: unknown
  ) => {
    return new UploadAdapter(loader);
  };
}
class UploadAdapter {
  loader;
  constructor(props: any) {
    this.loader = props;
  }
  async upload() {
    return { default: await imageHandler(await (await this.loader).file) };
  }
  abort() {}
}
