import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import img from "assets/png/img/배너이미지-01.jpg";
import img2 from "assets/png/img/배너이미지-02.jpg";
import img3 from "assets/png/img/배너이미지-03.jpg";
import img4 from "assets/png/img/배너이미지-04.jpg";

export const MainImg = styled.div`
  display: flex;
  width: 100%;
  transition: margin-left 1s;
  img {
    min-width: 100vw;
  }
`;

const Banner: React.FC = () => {
  const imgs = [img, img2, img3, img4];
  const [idx, setIdx] = useState(0);
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const slide = setInterval(() => {
      if (!ref.current) return;
      ref.current.style.marginLeft = `-${idx}00vw`;
      setIdx(idx >= 3 ? 0 : idx + 1);
    }, 5000);
    return () => clearInterval(slide);
  }, [idx]);
  return (
    <MainImg ref={ref}>
      {imgs.map(img => (
        <img src={img} alt="배너" key={img} />
      ))}
    </MainImg>
  );
};

export default Banner;
