import { TableBtn } from "components/table";
import { IFnQ } from "lib/dto";
import { getFnQPage } from "lib/req";
import React, { useEffect, useState } from "react";
import { ElementWrapper, Emphatic, Heading } from "style/layout";
import { Article, Q, A } from "./style";

function QNA(): JSX.Element {
  const [posts, setPosts] = useState<{
    fnq: IFnQ[];
    totalPage: number;
  }>();
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    getFnQPage(currentPage).then(res => res && setPosts(res.data));
  }, [currentPage]);
  return (
    <ElementWrapper>
      <Heading>자주 묻는 질문</Heading>
      <Article>
        {posts?.fnq.map(item => (
          <div>
            <Q>Q. {item.q}</Q>
            <A>
              A.{" "}
              {item.a.split("\n").map(e => (
                <>
                  {e}
                  <br />
                </>
              ))}
            </A>
          </div>
        ))}
        <TableBtn
          preBtnOnClick={() =>
            currentPage !== 1 && setCurrentPage(currentPage - 1)
          }
          nextBtnOnClick={() =>
            posts &&
            currentPage < posts.totalPage &&
            setCurrentPage(currentPage + 1)
          }
          currentPage={currentPage}
          totalPage={posts?.totalPage || 1}
        />
        <div>
          <Emphatic>문의</Emphatic>
          <div>
            <address>
              전남대병원 AI헬스케어의료기기 상용화지원센터 <br />
              <a href="mailto:aihmd@naver.com">aihmd@naver.com</a>
            </address>
          </div>
        </div>
      </Article>
    </ElementWrapper>
  );
}

export default QNA;
