import { AxiosError } from "axios";
import toast from "react-hot-toast";

export const ERROR_CODE_LIST = {
  CODE000: {
    message: "예상치 못한 에러입니다. 개발자에게 문의해주세요.",
    status: 500,
  },
  CODE001: { message: "어드민이 아닙니다.", status: 401 },
  CODE002: { message: "인자값이 부족합니다.", status: 400 },
  CODE003: { message: "Token 값을 찾을 수 없습니다.", status: 401 },
  CODE004: { message: "Token 인증이 실패하였습니다.", status: 400 },
  CODE005: { message: "잘못된 요청입니다.", status: 400 },
  CODE006: { message: "액세스토큰이 유효하지않습니다.", status: 401 },
  CODE007: { message: "리프레쉬토큰이 유효하지않습니다.", status: 400 },
  CODE008: { message: "허용되지않은 값입니다.", status: 400 },
  CODE009: {
    message: "비밀번호 또는 유저 인증에 실패하였습니다.",
    status: 401,
  },
  CODE010: { message: "기한이 만료되었습니다.", status: 400 },
  CODE011: { message: "해당 정보를 찾을 수 없습니다.", status: 404 },
};

export type ErrorCodeType = keyof typeof ERROR_CODE_LIST;

function errorCatch(
  err: AxiosError<{ success: false; code: ErrorCodeType; message: string }>
) {
  if (err.response) {
    if (!err.response.data.code) {
      console.log(err.response, "not Messsage");
      toast.error("다시 로그인해주세요.");
      // setTimeout(() => {
      //   global.location.replace("/login");
      // }, 1000);
    } else {
      toast.error(ERROR_CODE_LIST[err.response.data.code].message);
      if (err.response.status === 401) {
        toast.error("다시 로그인해주세요.");
        setTimeout(() => {
          global.location.replace("/login");
        }, 1000);
      }
    }
  } else if (err.request) {
    // 요청이 전송되었지만, 응답이 수신되지 않았습니다.
    // 'error.request'는 브라우저에서 XMLHtpRequest 인스턴스이고,
    // node.js에서는 http.ClientRequest 인스턴스입니다.
    console.log(err.request, "not response");
    toast.error(ERROR_CODE_LIST["CODE000"].message);
  } else {
    // 오류가 발생한 요청을 설정하는 동안 문제가 발생했습니다.
    console.log("Error", err.message);
    toast.error(ERROR_CODE_LIST["CODE000"].message);
  }

  return Promise.reject(err);
}

export default errorCatch;
