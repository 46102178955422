import React, { useEffect, useState } from "react";
import { ElementWrapper, Heading } from "style/layout";
import Table, { TableBtn } from "components/table";
import { getAnnouncementPage } from "lib/req";
import { IAnnouncement } from "lib/dto";

function Notice(): JSX.Element {
  const [posts, setPosts] = useState<{
    announcement: IAnnouncement[];
    totalPage: number;
  }>();
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    getAnnouncementPage(currentPage).then(res => {
      if (res?.data?.announcement?.length) {
        setPosts(res.data);
        return;
      }
      alert(`${currentPage} 페이지가 존재하지않습니다.`);
      setCurrentPage(currentPage - 1);
    });
  }, [currentPage]);

  return (
    <ElementWrapper>
      <Heading>공지사항</Heading>
      <Table
        path={"notice"}
        posts={
          posts?.announcement.length === 0 ? undefined : posts?.announcement
        }
      />
      <TableBtn
        preBtnOnClick={() =>
          currentPage !== 1 && setCurrentPage(currentPage - 1)
        }
        nextBtnOnClick={() =>
          posts &&
          currentPage < posts.totalPage &&
          setCurrentPage(currentPage + 1)
        }
        currentPage={currentPage}
        totalPage={posts?.totalPage || 1}
      />
    </ElementWrapper>
  );
}

export default Notice;
