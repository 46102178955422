//@ts-ignore
import CustomEditor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import React from "react";
import "components/editor/style.css";
import { UploadAdapterPlugin } from "lib/req";

const Editor: React.FC<{
  setContent: React.Dispatch<React.SetStateAction<string>>;
  content: string;
}> = ({ setContent, content }) => {
  return (
    <CKEditor
      config={{ extraPlugins: [UploadAdapterPlugin] }}
      editor={CustomEditor}
      data={content}
      onChange={(e, editor) => {
        const data = editor.getData();
        setContent(data);
      }}
    />
  );
};

export default Editor;
