import { createEquipment, imageHandler } from "lib/req";
import React, { useState } from "react";
import Button from "style/button";
import Input from "style/input";
import { ElementWrapper, Heading } from "style/layout";
import styled from "styled-components";
import Editor from "components/editor";

const CreateWrapper = styled.section`
  margin-top: 30px;
`;

function UseCreate() {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [file, setFile] = useState<FileList>();
  const [thumbnail, setThumbnail] = useState<FileList>();

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    if (e.target.files) setFile(e.target.files);
  };

  const handleThumbnailChange: React.ChangeEventHandler<
    HTMLInputElement
  > = e => {
    if (e.target.files) setThumbnail(e.target.files);
  };

  async function returnFileUrls() {
    const files: File[] = [];
    if (file) {
      for (let index = 0; index < file.length; index++) {
        files.push(file[index]);
      }
    }
    return await Promise.all(files.map(file => imageHandler(file)));
  }

  async function returnThumbnailUrls() {
    const files: File[] = [];
    if (thumbnail) {
      files.push(thumbnail[0]);
    }
    return await imageHandler(files[0]);
  }

  async function create() {
    returnFileUrls().then(files => {
      returnThumbnailUrls().then(thumbnails => {
        createEquipment({
          title,
          content,
          file: files,
          thumbnail: thumbnails,
        });
      });
    });
  }

  return (
    <ElementWrapper>
      <Heading>구축장비목록 글쓰기</Heading>
      <CreateWrapper>
        <Input
          onChange={e => setTitle(e.currentTarget.value)}
          placeholder="제목"
          type="text"
        />
        <Editor setContent={setContent} content={content} />
        <h3>파일</h3>
        <Input
          onChange={handleChange}
          placeholder="파일"
          multiple={true}
          type={"file"}
        />
        <h3>썸네일</h3>
        <Input
          onChange={handleThumbnailChange}
          placeholder="썸네일"
          multiple={true}
          type={"file"}
        />
        <Button onClick={() => create()}>저장</Button>
      </CreateWrapper>
    </ElementWrapper>
  );
}

export default UseCreate;
