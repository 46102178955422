/* eslint-disable @typescript-eslint/ban-ts-comment */
import Editor from "components/editor";
import { getNews, imageHandler, updateNews } from "lib/req";
import React, { useEffect, useState } from "react";

import ReactModal from "react-modal";
import Button from "style/button";
import Input from "style/input";
import modalController from "../modalController";

export interface EditProfileProps {
  children: React.ReactNode;
  idx: number;
}

function NewsEditModal({ children, idx }: EditProfileProps): JSX.Element {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [openModal, closeModal] = modalController(setModalIsOpen);

  const [filesUrl, setFilesUrl] = useState<string[]>();

  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [file, setFile] = useState<FileList>();

  useEffect(() => {
    getNews(idx).then(res => {
      if (res) {
        setFilesUrl(res.data.file);
        setTitle(res.data.title);
        setContent(res.data.content);
      }
    });
  }, [idx]);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    if (e.target.files) setFile(e.target.files);
  };

  async function returnFileUrls() {
    const files: File[] = [];
    if (file) {
      for (let index = 0; index < file.length; index++) {
        files.push(file[index]);
      }
    }
    return await Promise.all(files.map(file => imageHandler(file)));
  }

  async function create() {
    returnFileUrls().then(files => {
      if (filesUrl) {
        updateNews(idx, {
          title,
          content,
          file: [...files, ...filesUrl],
        }).then(() => closeModal());
      } else {
        updateNews(idx, {
          title,
          content,
          file: files,
        }).then(() => closeModal());
      }
    });
  }

  return (
    <>
      <button role="none" onClick={openModal}>
        {children}
      </button>
      {/* @ts-ignore */}
      <ReactModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        ariaHideApp={false}
        contentLabel="Edit Modal"
      >
        <Input
          onChange={e => setTitle(e.currentTarget.value)}
          value={title}
          type="text"
        />
        <Editor setContent={setContent} content={content} />
        <div
          style={{
            border: "1px solid #dfdfdf",
            padding: "0.625rem",
            margin: "0.625rem 0",
            width: "100%",
          }}
        >
          <h2>기존 파일</h2>
          {filesUrl &&
            filesUrl.map((res: string) => (
              <p key={res}>
                {res.split("/").slice(4)}
                <button
                  style={{ marginLeft: "5px" }}
                  onClick={() => {
                    setFilesUrl(filesUrl.filter(file => file !== res));
                  }}
                >
                  삭제
                </button>
              </p>
            ))}
        </div>
        <label style={{ width: "100%" }}>
          새롭게 추가할 파일
          <Input onChange={handleChange} multiple={true} type={"file"} />
        </label>
        <Button onClick={() => create()}>저장</Button>
      </ReactModal>
    </>
  );
}

export default NewsEditModal;
