import GroupImg from "assets/png/img/연구조직현황-01.jpg";
import { ElementWrapper, Heading } from "style/layout";
import React from "react";
import { ImgWrapper } from "./style";

function Group(): JSX.Element {
  return (
    <ElementWrapper>
      <Heading>조직도</Heading>
      <ImgWrapper>
        <img src={GroupImg} alt="연구 조직 현황" />
      </ImgWrapper>
    </ElementWrapper>
  );
}

export default Group;
