import styled from "styled-components";

export const ListBox = styled.li`
  position: relative;
  width: calc(33.3% - 1.25rem);
  padding: 0 0 30px 0;
  border: 1px solid #ddd;
  margin-bottom: 1.25rem;
  cursor: pointer;
  list-style: none;
  transition: all 0.3s linear;
  border-radius: 8px;
  width: 18.75rem;
  height: 18.75rem;
  font-weight: 700;
  overflow: hidden;
  a {
    border-radius: 10px;
  }
  a > img {
    border-radius: 0 0 8px 8px;
    width: 100%;
    height: 100%;
  }
  h2 {
    position: absolute;
    bottom: -7.5%;
    left: 0;
    right: 0;
    text-align: center;
    color: black;
  }
  &:hover {
    transform: scale(1.03);
  }
`;

export const ListBoxWrapper = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 3.125rem;
  text-align: center;
`;
