import styled from "styled-components";

export const TableBtn = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.625rem 0;
  button {
    padding: 0.313rem;
    background-color: #fff;
    border: 1px solid #dfdfdf;
    margin: 0 0.313rem;
    cursor: pointer;
  }
  button:hover {
    background-color: #008cd6;
    color: #fff;
  }
`;

export const Table = styled.table`
  width: 100%;
  th {
    background-color: #f3f3f3;
    font-weight: 700;
    text-align: left;
  }
  th,
  td {
    padding: 0.625rem;
  }
  tr {
    border: 1px solid #dfdfdf;
  }
  button {
    padding: 0;
    border: 0;
    background-color: #fff;
    color: #008cd6;
    cursor: pointer;
    text-align: center;
    width: 100%;
  }
`;
