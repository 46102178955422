import { IAnnouncement } from "lib/dto";
import { getAnnouncementPage } from "lib/req";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as S from "./style";

function Notice(): JSX.Element {
  const [notice, setNotice] = useState<IAnnouncement[]>([
    {
      idx: 1,
      createdAt: "1667925891331",
      updatedAt: "1667925891331",
      title: "로딩중.",
      content: "로딩중.",
      file: ["로딩중."],
      viewCount: 0,
    },
  ]);
  useEffect(() => {
    getAnnouncementPage(1).then(res => res && setNotice(res.data.announcement));
  }, []);
  return (
    <S.NoticeWrapper>
      <S.Notice>
        <S.Title>공지사항</S.Title>
        <S.PostWrapper>
          {notice.length > 4
            ? notice.slice(0, 4).map(data => (
                <Link to={`notice-detail/${data.idx}`}>
                  <S.Post key={data.idx}>
                    <S.DateWrapper>
                      <S.StrongDate>
                        {new Date(Number(data.createdAt)).getDate()}
                      </S.StrongDate>
                      <S.Date>
                        {new Date(Number(data.createdAt)).getFullYear()}.
                        {new Date(Number(data.createdAt)).getMonth() + 1}
                      </S.Date>
                    </S.DateWrapper>
                    <h3>
                      {data.title.length >= 30
                        ? data.title.slice(0, 27) + "..."
                        : data.title}
                    </h3>
                  </S.Post>
                </Link>
              ))
            : notice.map(data => (
                <Link
                  to={`notice-detail/${data.idx}`}
                  style={{ height: "45%" }}
                >
                  <S.Post key={data.idx}>
                    <S.DateWrapper>
                      <S.StrongDate>
                        {new Date(Number(data.createdAt)).getDate()}
                      </S.StrongDate>
                      <S.Date>
                        {new Date(Number(data.createdAt)).getFullYear()}.
                        {new Date(Number(data.createdAt)).getMonth() + 1}
                      </S.Date>
                    </S.DateWrapper>
                    <h3>
                      {data.title.length >= 30
                        ? data.title.slice(0, 27) + "..."
                        : data.title}
                    </h3>
                  </S.Post>
                </Link>
              ))}
        </S.PostWrapper>
      </S.Notice>
    </S.NoticeWrapper>
  );
}

export default Notice;
