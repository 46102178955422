import styled from "styled-components";

export const Article = styled.article`
  line-height: 1.875rem;
  & > div {
    margin: 1.25rem 0;
  }
`;

export const Q = styled.div`
  font-weight: 700;
  padding: 10px;
  width: 100%;
  border: 1px solid #c4c4c4;
`;

export const A = styled.div`
  padding: 10px;
  width: 100%;
  border: 1px solid #c4c4c4;
  background-color: #f2f2f2;
`;
