import React, { useEffect, useState } from "react";
import { IAnnouncement } from "lib/dto";
import { getAnnouncement } from "lib/req";
import { useParams } from "react-router-dom";
import { ElementWrapper, Files, Heading } from "style/layout";
import Viewer from "components/viewer";
import { dataFormat } from "lib/date";
import { isImg } from "lib/str";

function NoticeDetail() {
  const [data, setData] = useState<IAnnouncement>();
  let { id } = useParams();

  useEffect(() => {
    getAnnouncement(Number(id)).then(res => {
      res && setData(res.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (data == null) return <>{data}</>;

  return (
    <ElementWrapper>
      <Heading>{data.title}</Heading>
      <time style={{ display: "block", textAlign: "right" }}>
        작성일 : {dataFormat(new Date(Number(data.createdAt)))}
      </time>
      <p style={{ textAlign: "right" }}>
        조회수 : {Math.floor(data.viewCount / 2)}
      </p>
      <br />
      <br />
      {data.file.map(
        file =>
          isImg(file) && (
            <img
              key={file}
              style={{ display: "block", margin: "0 auto", maxWidth: "100%" }}
              src={file}
              onError={e => (e.currentTarget.style.display = "none")}
              alt="첨부자료"
            />
          )
      )}
      <br />
      <br />
      <Viewer content={data.content} />
      <Files>
        <h2 style={{ fontWeight: "lighter" }}>첨부파일</h2>
        <article>
          {data.file.map(file => (
            <p style={{ padding: "5px 0" }}>
              <a href={file}>{file.split("/").slice(4)}</a>
            </p>
          ))}
        </article>
      </Files>
    </ElementWrapper>
  );
}

export default NoticeDetail;
