import styled from "styled-components";

export const NoticeWrapper = styled.section`
  padding: 1.875rem 0 4.375rem 0;
`;

export const Notice = styled.div`
  margin: 0 auto;
  max-width: 1000px;
  @media screen and (max-width: 1100px) {
    max-width: 800px;
  }
  @media screen and (max-width: 900px) {
    margin: 0 20px;
  }
`;

export const Title = styled.h2`
  font-size: 1.25rem;
  font-weight: 700;
  margin: 0.625rem 0;
`;

export const PostWrapper = styled.ul`
  width: 100%;
  max-height: 180px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  a {
    width: 45%;
  }
  @media screen and (max-width: 700px) {
    flex-wrap: nowrap;
    a {
      width: 100%;
    }
  }
`;

export const Post = styled.li`
  display: flex;
  align-items: center;
  gap: 0.625rem;
  padding: 0.625rem 0.938rem 0.625rem 0;
  @media screen and (max-width: 700px) {
    width: 100%;
  }
`;

export const StrongDate = styled.span`
  font-size: 2.5rem;
`;

export const Date = styled.span`
  color: #c4c4c4;
  font-size: 0.75rem;
`;

export const DateWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
