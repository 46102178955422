import React from "react";
import { ElementWrapper, Heading } from "style/layout";
import VisionImg from "assets/png/img/비전과 목표-01.jpg";
import { ImgWrapper } from "./style";

function Vision(): JSX.Element {
  return (
    <ElementWrapper>
      <Heading>비전 및 목표</Heading>
      <ImgWrapper>
        <img src={VisionImg} alt="비전" />
      </ImgWrapper>
    </ElementWrapper>
  );
}

export default Vision;
